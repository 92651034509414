import  firebase from 'firebase';
import  admin from 'firebase-admin';

export const Robo = firebase.initializeApp(
  {
  
    // apiKey: "AIzaSyBz9YbIBKTGmuNsYQfG50GbPZyPLVchCPQ",
    // authDomain: "machines-f81d3.firebaseapp.com",
    // projectId: "machines-f81d3",
    // storageBucket: "machines-f81d3.appspot.com",
    // messagingSenderId: "702465692561",
    // appId: "1:702465692561:web:20bad17e9f69c5faa64030",
    // measurementId: "G-GBGBJFYHYF"
    
    // apiKey: "AIzaSyBmzumMo8hHMgrRiXy6TfyDbyzFE1rsdBU",
    // authDomain: "machinerobo-3995c.firebaseapp.com",
    // databaseURL: "https://machinerobo-3995c-default-rtdb.firebaseio.com",
    // projectId: "machinerobo-3995c",
    // storageBucket: "machinerobo-3995c.appspot.com",
    // messagingSenderId: "81983999070",
    // appId: "1:81983999070:web:864264ef4f4fb7326b78cf",
    // measurementId: "G-P7PF6YCPK1"
    // 21-aug-2023 personal id aamirnawaziiui pe hai
    apiKey: "AIzaSyB_Q5RhC4usGjrIEdxbt8RRu2rDQa1px00",
  authDomain: "robo-b0806.firebaseapp.com",
  databaseURL: "https://robo-b0806-default-rtdb.firebaseio.com",
  projectId: "robo-b0806",
  storageBucket: "robo-b0806.appspot.com",
  messagingSenderId: "1074894249113",
  appId: "1:1074894249113:web:7cfe0e7f112755a3e7ac08",
  measurementId: "G-0Q5ZBFC0XL"

}, 
  'Robo' // this name will be used to retrieve firebase instance. E.g. first.database();
);

export const HomeSampling = firebase.initializeApp(
  {
    authDomain: "metropole-laboratories.firebaseapp.com",
    databaseURL: "https://metropole-laboratories.firebaseio.com",
    projectId: "metropole-laboratories",
    storageBucket: "metropole-laboratories.appspot.com",
    messagingSenderId: "1023562329135",
    appId: "1:1023562329135:web:223c2cb0aa5ce56db72ada",
    measurementId: "G-MGCRV9LPWH"
  }, 
  'HomeSampling' // this name will be used to retrieve firebase instance. E.g. second.database();
);


