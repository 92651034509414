
import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import HelpIcon from '@material-ui/icons/Help';
import { isMobile, ConsoleView } from 'react-device-detect';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';


import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import ReactLoading from 'react-loading';

import Slide from "@material-ui/core/Slide";

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';

import FeedingTable from './FeedingTable'

import Autocomplete from '@material-ui/lab/Autocomplete';

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


import MaterialTable from 'material-table'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 1000,
    },
});

export default function ParentChildBulkUpdateTable() {
    const classes = useStyles();
    var date = new Date()
    date.setDate(date.getDate() - 2);

    const [from, setFrom] = React.useState(date)
    const [to, setTo] = React.useState(new Date())
    const [width, height] = [window.innerWidth, window.outerWidth];
    const [panel, setPanel] = React.useState('All')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const [loading, setLoading] = React.useState(true)
    const [panelList, setpanelList] = React.useState([])
    const [laborder, setLabOrder] = React.useState(false)
   
  const [pdfScanFlag,setpdfScanFlag]=React.useState(false)
    
    const [branches, setBranches] = React.useState([])
    const [branch, setBranch] = React.useState("")
    const [selectBranch, setSelectBranch] = React.useState([])
    // 9-jan-2023
    // for departments
    const [departments,setDepartments] = React.useState([
        {
            id:0,
            name:"ALL"
        },
        {
        id:1,
        name:"Accessioning"
    },
    {
        id:2,
        name:"Administration"
    },
])
const [selectedDepartment, setselectedDepartment] = React.useState("ALL")

//for CallingUpTestsList_api 
const [feedResultInvoices,setfeedResultInvoices] = React.useState([
  
])
const [CallingUpTestsList,setCallingUpTestsList]=React.useState()
const [selectedFeedInvoice, setselectedFeedInvoice] = React.useState()
//for Test Title jo invoice select hui us se jo related test obj hn wo es me daly hn
const [testList,settestList] = React.useState([])
const [selectedTest, setselectedTest] = React.useState()
// jo test parameter next api se us k lye
const [testParametersFull,settestParametersFull] = React.useState([])
const [testParameter, settestParameter] = React.useState()
// SET PARENT table data heading of test jis ki id k sth child data match kre ga 
const [testParentTableParameter, settestParentTableParameter] = React.useState()

const [comments, setComments] = React.useState()

const [Patientid, setPatientid] = React.useState("")
// const [PatientMobile, setPatientMobile] = React.useState()
// used for search and Color change of selected invoice in Invoices  table  1-feb-2024
const [Invoiceid, setInvoiceid] = React.useState("")
// used for search and Color change of selected invoice in Invoices  table 
const [selectedTestAuxId, setselectedTestAuxId] = React.useState()
// for show name in material Table
const [selectedTestName, setselectedTestName] = React.useState("Tests Result Feeding ")



const [PatientName, setPatientName] = React.useState()
const [ScanPDF, setScanPDF] = React.useState('')






    const [selectOrders, setSelectedOrders] = React.useState([])
    const [state, setState] = React.useState({
        status: 'All',
    });

    
  
    // multiple test 
    const [selectedParentData,SetSelectedParentData]=React.useState()
      const [selectedRows, setSelectedRows] = React.useState([]); // State to track selected rows
  // Data for the parent table (departments)
  const parentData = [
    { id: "CompleteBloodPicture", name: 'Complete Blood Picture ' },
    // { id: "Thyroid Function Tests (FT3+FT4+TSH)", name: 'Thyroid Function Tests ye display k lye ' },
    // Add more departments as needed
  ];

  // Data for the child table (employees)
  const childData = {
    "Thyroid Function Tests": [ // Employees in HR department
      { id: 101, name: 'Free T-3', refrange: '12-17' },
      { id: 102, name: 'Free T-4', refrange: '16-18' },
      { id: 103, name: 'TSH', refrange: '18-20' },

      // Add more employees as needed
    ],
    "Thyroid Function Tests (FT3+FT4+TSH)": [ // Employees in Finance department
      { id: 201, name: 'Alice Johnson', refrange: 'Financial Analyst' },
      { id: 202, name: 'Bob Williams', refrange: 'Accountant' },
      // Add more employees as needed
    ],
    // Add more departments' employees as needed
  };

  // Columns configuration for the parent table (departments)
  const parentColumns = [
    { title: 'All Tests', field: 'name' },
    // Add more columns as needed
  ];

  // Columns configuration for the child table (employees)
  const childColumns = [
    { title: 'Test Title', field: 'parameter_title' },
    { title: 'Result', field: 'result_value' },
    { title: 'Result', field: 'result_value' },
    { title: 'Ref Range', field: 'refrange' },
    { title: 'Unit', field: 'test_units' },
    { title: 'Previous 1', field: 'previous_result_1' },
    { title: 'Previous 2', field: 'previous_result_2' },



    // Add more columns as needed
  ];
  const MyNewTitle = ({ text = "Table Title", variant = "h6" }) => (
    <Typography
      variant={100}
      style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
    >
      {text}
    </Typography>
  );

    useEffect(() => {
        if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://invoicing.mpl-labs.pk/login"
        }
        if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://invoicing.mpl-labs.pk"
        }
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
            setBranches(response)

        })
        var patient_id = localStorage.getItem('patient_id')
        var role = localStorage.getItem('role')



        var branchID = localStorage.getItem('branch_id')
        var from = new Date();
        from.setDate(from.getDate() - 2);
        var to = new Date();

        var objj = {
          branch_id:parseInt(branchID),
          user_id:localStorage.getItem('user_id'),
          date_start:from.toISOString(),
          date_end:to.toISOString(),
          patient_id:"",
          invoice_id:"",
          array_dep_id:[]

        }
        console.log("obj is "+JSON.stringify(objj))
fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsList_api", {
            method: "POST",
            body: JSON.stringify(objj)
        }).then((res) => res.json()).then((response) => {
            console.log("Check RF_CallingUpTestList_api API"+JSON.stringify(response))

         var   obj={}
         var objnew=[]
            response.map((i,index)=>{
  
  console.log(` is not in the array.`);
  objnew.push({
...i,

  })
            })
            // console.log("check obj value"+JSON.stringify(objnew))
                     // 1-feb-2024 1
         // setfeedResultInvoices(objnew)
            setCallingUpTestsList(objnew)


           console.log("check 440 CallingUpTestsList"+JSON.stringify(objnew))
        })
   

            setLoading(false)


        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
            .then((result) => {
                setpanelList(result)
            })
        if (localStorage.getItem('org_branch_id') == '0') {
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
                setBranches(response)
                var obj = {}
                response.map((item) => {
                    obj[item.BranchID] = item.BranchName
                })
                setSelectBranch(obj)

                setBranch(localStorage.getItem('branch_id'))
            })
        }
setBranch("ALL")



    }, [])
    Date.prototype.hhmm = function () {
        var mm = this.getMinutes();
        var hh = this.getHours();

        return [
            (hh > 9 ? '' : '0') + hh,
            (mm > 9 ? '' : '0') + mm
        ].join(':');
    };

    Date.prototype.ddmmyyy = function () {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [
            (dd > 9 ? '' : '0') + dd,
            (mm > 9 ? '' : '0') + mm,
            this.getFullYear()
        ].join('/');
    };
   
      const columnsNew = [
        // { title: 'Name', field: 'name', editable: 'never' },

        { title: 'Test Title', field: 'parameter_title', editable: 'never' },

        { title: 'Result', field: '',  },
        { title: 'Result', field: 'result_value', },
        { title: 'Ref Range', field: 'ref_range', editable: 'never' },
        { title: 'Unit', field: 'test_units', editable: 'never' },
        { title: 'Previous 1', field: 'previous_result_1',editable: 'never'  },
        { title: 'Previous 1', field: 'previous_result_2',editable: 'never'  },


        

    ];
    const columns = [
      { title: 'S/N', field: 'serial_no', editable: 'never'  },

      { title: 'Test Title', field: 'test_title', editable: 'never' },
      { title: 'Invoice_Id', field: 'invoice_id',editable: 'never'  },
      // { title: 'select', field: 'select', editable: 'never'  },

      // { title: 'Ref Range', field: 'ref_range', editable: 'never' },
      // { title: 'Unit', field: 'test_units', editable: 'never' },
      // { title: 'Previous 1', field: 'previous_result_1',editable: 'never'  },
      // { title: 'Previous 1', field: 'previous_result_2',editable: 'never'  },


      

  ];
   
      // for pdf
      const handleScanPDF = async (event) => {
        const file = event.target.files[0]
        console.log(file)
        var fileInput = document.getElementById('ScanPDF');
        var filePath = fileInput.value;
        var allowedExtensions = /(\.pdf)$/i;
    
        if (!allowedExtensions.exec(filePath)) {
          alert('Please upload file having extensions .pdf only.');
          fileInput.value = '';
          setScanPDF('')
          return false;
        } else {
          const base64 = await convertBase64(file)
          console.log("after convert into base64"+base64)
          // console.log("after convert into base64"+base64.split('base64,')[1])
    
          setScanPDF(base64)
        }
    
      }
      const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
      }
    return (
        <>
            <Paper className={classes.root}>
                {/* <GridContainer style={{ padding: '2em' }}> */}
                <GridContainer style={{ paddingLeft: '2em',paddingRight: '2em',marginTop:"" }}>
                    
                
                            <GridItem xs={6} sm={6} md={4} lg={4} style={{marginBottom:"1%",marginTop:"0.5em",display:"flex",}}  >
                                <FormControl variant="filled" className={classes.formControl} style={{ width: "8em", marginLeft:0 }} >
                                    <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={branch}
                                        onChange={(e) => {
                                            var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                                            if (result) {
                                                setBranch(e.target.value)
                                                localStorage.setItem('branch_id', e.target.value)
                                                localStorage.setItem('branch_name', selectBranch[e.target.value])
                                                window.location.reload()
                                            }

                                        }}
                                    >
                                        <MenuItem value={"0"} disabled>Select</MenuItem>
                                        {
                                            branches.map((item) => {
                                                return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

                                            })
                                        }
                                    </Select>
                                </FormControl>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker style={{ marginLeft:20,width:"8.5em"}}
                                variant="inline"
                                inputVariant="outlined"
                                label="From"
                                format="dd/MM/yyyy"
                                value={from}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setFrom(date)}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker
                            style={{ marginLeft: 20,width:"8.5em"}}
                                variant="inline"
                                inputVariant="outlined"
                                label="To"
                                format="dd/MM/yyyy"
                                value={to}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setTo(date)}
                            />
                        </MuiPickersUtilsProvider>
                            </GridItem>
                            
                    
                    {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{marginTop:"0.5em"}}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="From"
                                format="dd/MM/yyyy"
                                value={from}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setFrom(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem> */}
                    {/* {
                        width < 600
                            ?
                            <GridItem xs={6} sm={6} md={0} lg={0}>

                            </GridItem> : ""
                    } */}

                    {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{marginTop:"0.5em"}}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="To"
                                format="dd/MM/yyyy"
                                value={to}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setTo(date)}
                            />
                        </MuiPickersUtilsProvider>

                    </GridItem> */}
                    {/* {
                        width < 600
                            ?
                            <GridItem xs={6} sm={6} md={0} lg={0}>

                            </GridItem> : ""
                    }
                     */}
            {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{}}>
                

            
            </GridItem> */}
            {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{}}>

            </GridItem> */}
            <GridItem xs={6} sm={6} md={6} lg={6} style={{display:"flex",marginTop:"0.5em",}}>
            <Autocomplete
                  id="Ref_By_Doctor"

                  options={departments}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.name + ""}
                  style={{
                    width: "8em",
                    
                    marginRight: '1em'
                  }}
                  // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
                  onChange={(event, newValue) => {
                 
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Departments"
                      variant="outlined"
                      style={{
                        // width: "30em"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
            <TextField
              id="outlined-required"
              label="Patient ID"
              value={Patientid}
              onChange={(e) => {
                setPatientid(e.target.value);
              }}
              style={{
                width: "8em",
                
                marginRight: '1em'
              }}
              variant="outlined"
            />

            <TextField
              id="outlined-required"
              label="Invoice ID"
              value={Invoiceid}
              onChange={(e) => {
                setInvoiceid(e.target.value);
              }}
              style={{
                width: "8em",
                
                marginRight: '1em'
              }}
              variant="outlined"
            />
                                  <Button variant="contained"  style={{backgroundColor:"#196c91",color:"white", width: "8em",maxHeight:"4em",
                
                marginRight: '1em'}} disabled={loading}
   onClick={() => {
    // alert("DATE from"+from+"DATE to "+to+"invoice id"+Invoiceid+"patient iD"+Patientid)
         //  SeveRecordHandler()
        var branchID = localStorage.getItem('branch_id')

         var obj = {
          branch_id:parseInt(branchID),
          user_id:localStorage.getItem('user_id'),
          date_start:from.toISOString(),
          date_end:to.toISOString(),
          patient_id:Patientid,
          invoice_id:Invoiceid,
          array_dep_id:[]

        }
         console.log("checj obj "+JSON.stringify(obj))
         fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsList_api", {
            method: "POST",
            body: JSON.stringify(obj)
        }).then((res) => res.json()).then((response) => {
            console.log("Check RF_CallingUpTestList_api API"+JSON.stringify(response))
            // 1-feb-2024 1
            // setCallingUpTestsList(response)

         var   obj={}
         var objnew=[]
            response.map((i,index)=>{
            // 1-feb-2024 1

                // const matchingPersons = objnew?.filter(data => data.invoice_id == i.invoice_id);
// if (matchingPersons.length > 0) { 
//   console.log(`is in the array. Matching objects:`, matchingPersons +"and "+JSON.stringify(i.invoice_id));
// } else {
//   console.log(` is not in the array.`);
//   objnew.push({invoice_id:i.invoice_id,
//     serial_no:i.serial_no,

//   })
// }
  console.log(` is not in the array.`);
  objnew.push({
...i,

  })
            })

            setCallingUpTestsList(objnew)           
        })
        }}                  >
                            Search<SearchIcon />
                        </Button>
                    
                       
            </GridItem>
                   
                    
                    <GridItem xs={12} sm={12} md={2} lg={2} style={{ margin: 'auto',display:"flex",justifyContent:"center",alignItems:"center" }} >

                    <div  style={{fontSize:15,justifyContent:"start",alignItems:"start",display:"flex",flexDirection:"column",}}>
                        <p>helloaaaaa a aaa1</p>
                        <p style={{marginTop:-10}}>hello2</p>

                        </div>
                        <div  style={{fontSize:15,justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",}}>
                        <p>hello3</p>
                        <p style={{marginTop:-10}}>hello4</p>

                        </div>

                    </GridItem>
                    </GridContainer>
     

                {loading && localStorage.getItem('branch_id') != '0' ?
                    <center>
                        <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
                    </center>
                    :
                    <>
                                 <GridContainer style={{ paddingLeft: '2em',paddingRight: '2em',marginTop:"" }}>

                  
                   
                          <GridItem md={4} lg={4} style={{ }}>
       <MaterialTable
      // title="Invoices"
      title={<MyNewTitle variant="h1" text="Invoices" />}
      columns={columns}
      data={CallingUpTestsList}
      options={{
        // paging: false,
        pageSize: 5, // Set the desired number of rows per page
       
         
   
        headerStyle: {
          backgroundColor:"#196c91",
          color: "#FFF",
          fontSize: 10,
          // textAlign: "center",
          fontWeight: "bold",
          // minHeight:"50em"
        },
      
        rowStyle: rowData => {
          // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
          //   return {};
          // }
          // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
          return {backgroundColor:rowData.if_late &&rowData.invoice_id!=Invoiceid &&rowData.aux_id!=selectedTestAuxId? "#ff1212":rowData.invoice_id==Invoiceid && rowData.aux_id==selectedTestAuxId?"#cbcbcb":"",
          color:rowData.if_late &&rowData.invoice_id!=Invoiceid? "white":"",
          fontSize: 10,
          
        };
        }
      }}
      onRowClick={(event, rowData, togglePanel) => {
        // setOpenRow(openRow === rowData.id ? null : rowData.id);
        // togglePanel();
        console.log("data is "+JSON.stringify(rowData))
        
        
      var j=0

        var object = {   
          // aux_id:594980,
          // invoice_id:"0502208",
          // test_id:"UR-001-T-0409" 
          aux_id:rowData.aux_id,
          invoice_id:rowData.invoice_id,
          test_id:rowData.text_id
        }
        console.log("object is "+JSON.stringify(object))
       fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsParameters_api", {
            method: "POST",
            body: JSON.stringify(object)
        }).then((res) => res.json()).then((response) => {
            console.log("Check full response"+JSON.stringify(response))
            console.log("Check RF_CallingUpTestParameter_api"+JSON.stringify(response.parameters_obj))
            // {response.parameters_obj.map(parameter => (
            //   <li key={parameter.aux_id}>{parameter.parameter_title}</li>
            // ))}
//  settestParentTableParameter(response)
const arrayNames = Object.keys(response)
console.log("array names is ")
console.log(arrayNames[0])



            settestParametersFull(response)
            settestParameter({"CompleteBloodPicture":response.parameters_obj})
            console.log("check 1048 "+JSON.stringify({"CompleteBloodPicture":response.parameters_obj}))
            setInvoiceid(rowData.invoice_id)
            setselectedTestAuxId(rowData.aux_id)
            setselectedTestName(rowData.test_title) 

            
        })
       
      }}
      
    />
</GridItem>

                          <GridItem md={8} lg={8} style={{ paddingRight:"2em", }}>
                          <MaterialTable
      // title="Result Test Feeding"
      title={<MyNewTitle variant="h1" text="Result Test Feeding" />}

      columns={parentColumns}
      data={parentData}
      

      detailPanel={rowData => (
        <MaterialTable
          // title="Test"
      title={<MyNewTitle variant="h1" text="Test" />}
          
          columns={columnsNew}
          // data={childData[rowData.id]}
          data={testParameter[rowData.id]}

          onSelectionChange={rows => setSelectedRows(rows)}
          options={{ paging: false, search: true,
            headerStyle: {
              backgroundColor:"#196c91",
              color: "#FFF",
              fontSize: 10,
              // textAlign: "center",
              fontWeight: "bold",
              // minHeight:"50em"
            },
          //  pageSize: 10,
        actionsColumnIndex: -1,
        rowStyle: rowData => {
          // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
          //   return {};
          // }
          // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
          return {
          fontSize: 10,
          
        };
        } }}
        
          editable={{
            onBulkUpdate: changes =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                console.log(changes)
                const rows=Object.values(changes)
                const updatedData = [...testParameter[rowData.id]];
                console.log("ye testparameter hai")
                console.log(testParameter)


                console.log("ye updated hai")
                console.log(updatedData)
                console.log("ye object.value hai")
                console.log(rows)


                // changes.forEach(change => {
                //   updatedData[change.oldData.id - 1] = change.newData;
                // });
                rows.map (emp=>{
                  console.log(emp)
               let  index=emp.oldData.tableData.id
                  updatedData[index]=emp.newData
                })
                console.log("ye updated updatedDATAhai")
                console.log(updatedData)
            settestParameter({"CompleteBloodPicture":updatedData})

                // settestParameter({rowData.id:updatedData})
                // Update the data source
                // Call your API or update your data store here
                resolve();
              }, 600);
            }),
          }}
        />
      )}
      onSelectionChange={rows =>
        //  setSelectedRows(rows)
        console.log("check "+JSON.stringify(rows))
        }
      
      actions={[
        {
          tooltip: 'Bulk Update',
          icon: 'edit',
          onClick: () => {
            // Perform bulk update action
            console.log('Selected rows:', selectedRows);
            // Implement your logic to update selected rows here
          },
        },
      ]}
      options={{
        selection: true,
        headerStyle: {
       
          fontSize: 10,
          // textAlign: "center",
  
          // minHeight:"50em"
        },
       
          //  pageSize: 10,
        actionsColumnIndex: -1,
        rowStyle: rowData => {
          // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
          //   return {};
          // }
          // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
          return {
          fontSize: 10,
          
        };
        } 
      }}
    />
        
    {/* <MaterialTable
      title={selectedTestName}
      columns={columnsNew}
      data={testParameter}
      // options={{actionsColumnIndex: -1  }}
      
      options={{
        // paging: false,
        pageSize: 10,
        actionsColumnIndex: -1,
        headerStyle: {
          backgroundColor:"#196c91",

          color: "#FFF",
          // fontSize: "17px",
          // textAlign: "center",
          fontWeight: "bold",
          // minHeight:"50em"
        },
        // grouping: true,
        rowStyle: rowData => {
      
          // return {backgroundColor:rowData.rfd>0? "#ffffe0":"", };
        }
      }}
      // yhan se 
      // parentChildData={(row, rows) => 
      //   rows.find(a => a.id == row.parentId)
      
      // }
      // yhan tak 
      

      editable={{
        isEditable: () => false, // Disable default row editing
        onBulkUpdate: (changes) =>
          new Promise((resolve, reject) => {
            const rows=Object.values(changes)
            console.log("check edit ",rows)

            const updatedRows = [...testParameter];
            let index;
            rows.map (emp=>{
              console.log(emp.oldData.tableData.id)
              index=emp.oldData.tableData.id
              updatedRows[index]=emp.newData
            })
            settestParameter(updatedRows)
            console.log(updatedRows)
          
            resolve();
          }),
      }}
    /> */}
    
   
                        
                                </GridItem>
                              
                             

                        </GridContainer>
                        <GridContainer>
                     
                     <GridItem md={4} lg={4}>
                     <span style={{
       display: 'flex',
       flexDirection: "column",
       marginTop: 6,
       marginLeft:25
     }}>
       <b>Comments</b>
       <TextareaAutosize
         rowsMax={4}
         style={{
           padding: '0.5em',
           height: '3em',
           marginLeft: 1
         }}
         value={comments}
         onChange={(e) => {
           setComments(e.target.value)
         }}
         placeholder="Comments to Print Ends of Reports"
       />
       </span>
                     </GridItem>
                     <GridItem md={2} lg={2}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
       alert("hello")
            //  SeveRecordHandler()
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
            //  width: "1.5em",
            //  padding: "2.5em",
            paddingTop:"13%",paddingBottom:"13%",
             height: "3em",
             marginLeft: "2px",
             marginTop:25,
            //  marginBottom:10
            backgroundColor:"#196c91"
           }}>
               Select Comments
           </Button>
                     </GridItem>
                     <GridItem md={6} lg={6} style={{ paddingTop: '1.5em' }}>
                          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91"}}
            >
 <FormControlLabel
              control={<Checkbox checked={pdfScanFlag} onChange={(event)=>{
                setpdfScanFlag(event.target.checked)
              }} name="Scan PDF" />}
              label="Scan PDF"
            />
          
            </Button>
            {
            !pdfScanFlag
            ?
            <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"}}

          >
            <b>Attach PDF</b>
            <input type="file" variant="contained"
              // color="primary"
              onChange={(e) => { handleScanPDF(e) }}
              id="ScanPDF" name="pdf" accept="application/pdf"
              className={classes.button}
              aria-label="Attach PDF"
            ></input>
          </Button>
          :<></>
}
{
           pdfScanFlag ?
           <>
            <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"}}

            onClick={async()=>{
              try{
                if(document.getElementById('response').innerHTML==""){
                  
                }else{
                  setScanPDF(document.getElementById('response').innerHTML)
                }
            }
            catch(e){
              console.log(e)
              alert("Scan File First!!")
            }
            }}
          >
            <b>{ScanPDF=="" ? "Load Scan PDF File" : "Loaded SCAN PDF"}</b>
          
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"}}

            onClick={async()=>{
              window.scanToLocalDisk()
            }}
          >
            <b>Scan PDF</b>
            <div id="response" hidden={true} ></div>
          </Button>
          </>
          :
          null
         }
                                </GridItem>
                                
                     </GridContainer>
                     <GridContainer>
                     
                     <GridItem md={4} lg={4}>
                     <span style={{
       display: 'flex',
       flexDirection: "column",
       marginTop: 6,
       marginLeft:25
     }}>
       <b>Remarks (Internal Use)</b>
       <TextareaAutosize
         rowsMax={4}
         style={{
           padding: '0.5em',
           height: '3em',
           marginLeft: 1
         }}
         value={comments}
         onChange={(e) => {
           setComments(e.target.value)
         }}
         placeholder="Remarks for Internal use only "
       /></span>
                     </GridItem>
                     <GridItem md={2} lg={2}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
       alert("hello")
            //  SeveRecordHandler()
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
            //  width: "1.5em",
            //  padding: "2.5em",
            paddingTop:"13%",paddingBottom:"13%",
             height: "3em",
             
             marginTop:25,
             backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
               Result From LMS
           </Button>
                     </GridItem>
                     <GridItem md={2} lg={2}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
       alert("hello")
            //  SeveRecordHandler()
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
            //  width: "1.5em",
            //  padding: "2.5em",
            paddingTop:"13%",paddingBottom:"13%",
             height: "3em",
            //  marginLeft: "2px",
             marginTop:25,
             backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
               Reject to phlebotomy
           </Button>
           
                     </GridItem>
                     <GridItem md={3} lg={3}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
      //  alert("hello")
      var obj ={}
   var data= testParameter.map((item)=>{
// console.log("value in testParameter is "+JSON.stringify(item))
delete item.tableData
return item
      })
      console.log("parameter Full"+JSON.stringify(testParametersFull))

      // console.log("value delete change"+JSON.stringify(testParameter))
      testParametersFull.parameters_obj=testParameter
      // settestParametersFull(...testParametersFull)

      // console.log("parameter that will change"+JSON.stringify(testParameter))
      console.log("parameter Full new"+JSON.stringify(testParametersFull))

            //  SeveRecordHandler()
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
            //  width: "1.5em",
             padding: "2.5em",
             paddingLeft:"3em",
             paddingRight:"3em",
            // paddingTop:"13%",paddingBottom:"13%",
             height: "3em",
             marginLeft: "3.5em",
             marginTop:25,
             backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
           Save Records
           </Button>
                     </GridItem>
                    
                                
                     </GridContainer>
                    </>
                }
                


            </Paper>


        </>
    );
}

// import React, { useState } from 'react';
// import MaterialTable from 'material-table';

// const ParentChildBulkUpdateTable = () => {
//   const [selectedRows, setSelectedRows] = useState([]); // State to track selected rows

//   // Data for the parent table (departments)
//   const parentData = [
//     { id: "Thyroid Function Tests (FT3+FT4+TSH)", name: 'Thyroid Function Tests (FT3+FT4+TSH)' },
//     { id: "Thyroid Function Tests (FT3+FT4+TSH)", name: 'Thyroid Function Tests (FT3+FT4+TSH)' },
//     // Add more departments as needed
//   ];

//   // Data for the child table (employees)
//   const childData = {
//     "Thyroid Function Tests (FT3+FT4+TSH)": [ // Employees in HR department
//       { id: 101, name: 'Free T-3', refrange: '12-17' },
//       { id: 102, name: 'Free T-4', refrange: '16-18' },
//       { id: 103, name: 'TSH', refrange: '18-20' },

//       // Add more employees as needed
//     ],
//     "Thyroid Function Tests (FT3+FT4+TSH)": [ // Employees in Finance department
//       { id: 201, name: 'Alice Johnson', position: 'Financial Analyst' },
//       { id: 202, name: 'Bob Williams', position: 'Accountant' },
//       // Add more employees as needed
//     ],
//     // Add more departments' employees as needed
//   };

//   // Columns configuration for the parent table (departments)
//   const parentColumns = [
//     { title: 'All Tests', field: 'name' },
//     // Add more columns as needed
//   ];

//   // Columns configuration for the child table (employees)
//   const childColumns = [
//     { title: 'ID', field: 'id' },
//     { title: 'Name', field: 'name' },
//     { title: 'Ref Range', field: 'refrange' },
//     // Add more columns as needed
//   ];

//   return (
    // <MaterialTable
    //   title="Result Test Feeding"
    //   columns={parentColumns}
    //   data={parentData}
    //   detailPanel={rowData => (
    //     <MaterialTable
    //       title="Employees"
    //       columns={childColumns}
    //       data={childData[rowData.id]}
    //       onSelectionChange={rows => setSelectedRows(rows)}
    //       options={{ paging: false, search: true, }}
    //       editable={{
    //         onBulkUpdate: changes =>
    //         new Promise((resolve, reject) => {
    //           setTimeout(() => {
    //             const updatedData = [...childData];
    //             changes.forEach(change => {
    //               updatedData[change.oldData.id - 1] = change.newData;
    //             });
    //             // Update the data source
    //             // Call your API or update your data store here
    //             resolve();
    //           }, 600);
    //         }),
    //       }}
    //     />
    //   )}
    //   onSelectionChange={rows =>
    //     //  setSelectedRows(rows)
    //     console.log("check "+JSON.stringify(rows))
    //     }
      
    //   actions={[
    //     {
    //       tooltip: 'Bulk Update',
    //       icon: 'edit',
    //       onClick: () => {
    //         // Perform bulk update action
    //         console.log('Selected rows:', selectedRows);
    //         // Implement your logic to update selected rows here
    //       },
    //     },
    //   ]}
    //   options={{
    //     selection: true,
    //   }}
    // />
//   );
// };

// export default ParentChildBulkUpdateTable;
