import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from './mpl-logo.png'
// import footer from '../../../assets/img/mpl-footer.png'

const styles = StyleSheet.create({
  page: {
    paddingTop: 180,
    paddingBottom: 60,
    fontSize: 8,
    lineHeight: 1.5,
    flexDirection: 'column'
  },
  box: { width: '100%', marginBottom: 30, borderRadius: 5 },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center'
  },

  logo: {
    width: 200,
    height: 100
  },
  barcode: {
    width: 150,
    height: 40
  },
  titleContainer: {
    marginTop: 10
  },
  reportTitle: {
    color: 'black',
    fontSize: 15,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration: 'underline'
  }
});
const date = new Date()
Date.prototype.ddmmyyy = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [
    (dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    this.getFullYear()
  ].join('/');
};
var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
const Invoice = ({ invoice, discount, panelName, branchName, panel,forName }) => (
  <Document>
    <Page style={styles.page} size="A4" wrap={true} >
      <View fixed style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        top: 0,
        position: 'absolute',
        paddingLeft: 10,
        alignItems: "center",
        paddingRight: 10,
        paddingTop: 10
      }} >
          <View style={{
          flex: '0.5',
          flexDirection: 'column',
          marginLeft: 20
        }}>
          <Image style={styles.logo} src={logo} />
          
        </View>
      

        <View style={{ display: 'flex', flexDirection: 'column' , alignItems:"right", marginLeft:370, width:150}}>
          <Text style={{
            fontFamily: 'Helvetica-Bold',
            fontSize: 7
          }}>Date</Text>
          <Text style={{ fontFamily: 'Helvetica', color: 'grey' }}>{date.ddmmyyy()}</Text>
          <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 7 }}>Time</Text>
          <Text style={{ fontFamily: 'Helvetica', color: 'grey' }}>{time}</Text>
          {panelName==""?<>
        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 7 }}>{forName=="" ? "": "Issued For"}</Text>
        <Text style={{ fontFamily: 'Helvetica', color: 'grey', color:"red" ,}}>{forName=="" ? "": forName}</Text>
        </>:null
        }
          <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 7 }}>Branch</Text>
          <Text style={{ fontFamily: 'Helvetica', color: 'grey', color:"red" }}>{branchName}</Text>
        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 7 }}>{panelName=="" ? "": "Panel"}</Text>
        <View style={{width:150, textAlign:"right"}}>
          <Text style={{ fontFamily: 'Helvetica', color: 'grey', color:"red" , position:"absolute", width:"100%"}}>{panelName=="" ? "": panelName}</Text>
          </View>
          
        </View>
      
        
       
        <View style={{
          display: 'flex',
          position: 'absolute',
          width: '100%',
          marginLeft: 10,
          paddingTop: 202
        }}>
           <Text style={styles.reportTitle}>Price List</Text>
          <InvoiceTableHeader />
        </View>


      </View>

      <View style={{ width: '100%', paddingRight: 10, paddingLeft: 10 , marginTop:-5}}

      >
        
        <InvoiceItemsTable invoice={invoice} discount={discount} panel={panelName == "" ? false : true} />
      </View>
      <Text style={{ fontFamily: 'Helvetica',marginLeft:10,color:"red" }}>Note: The validity of this quote is 01 week from its date of issuance.</Text>

      <View fixed style={{ position: 'absolute', width: '100%', bottom: 0 }}>
<View style={{ display: 'flex', flexDirection: 'column' , alignItems:"LEFT", marginLeft:20 , width:90, marginBottom:10}}>
          <Text style={{
            fontFamily: 'Helvetica-Bold',
            fontSize: 6,
            borderWidth:0.5,
            textAlign:'center',
            width:"100%",
            padding:"5em"
          }}>Doc No: MPL-GEN-F041</Text>
          <View style={{
            fontFamily: 'Helvetica-Bold',
            fontSize: 5,
            width:"100%",
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
       
            borderWidth:0.5,
          }}>
            <Text style={{padding:"3em",borderRightWidth:0.5, paddingRight:'10em'}}>Issue No # 01</Text>
            <Text style={{padding:"3em"}}>Issue Date</Text>
          </View>
          <View style={{
            fontFamily: 'Helvetica-Bold',
            fontSize: 5,
            width:"100%",
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
       
            borderWidth:0.5,
          }}>
            <Text style={{padding:"3em",borderRightWidth:0.5 , paddingRight:'16em'}}>Page 1 of 1</Text>
            <Text style={{padding:"3em"}}>14-06- 2022</Text>
          </View>
       
          
        </View>
</View>


    </Page>
  </Document>
);

export default Invoice