import React, { useEffect, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ReactLoading from 'react-loading';
import Slide from "@material-ui/core/Slide";
import SearchIcon from '@material-ui/icons/Search';
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaterialTable from "material-table";
import ReceiptIcon from '@material-ui/icons/Receipt';
//for dialogbox of invoice
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
//for pdf
import { PDFViewer } from '@react-pdf/renderer'
import Invoice from './PDF/Invoice.js'
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';






const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 1000,
    },
});


export default function Lab_Tests() {
    const classes = useStyles();
    var date = new Date()
    var temp_role = localStorage.getItem('role')
    // date.setDate(date.getDate() - 2);
    date.setDate(date.getDate()-1);

    const [from, setFrom] = React.useState(date)
    const [to, setTo] = React.useState(new Date())
    const [width, height] = [window.innerWidth, window.outerWidth];
    //31-jan-2023
    const [panel, setPanel] = React.useState('')
  const [panelList,setPanelList]=React.useState([])
    const [loading, setLoading] = React.useState(true)
  
  //for invoice search seprate
  const [invoiceID, setInvoiceID] = React.useState("")
  // for Invoice pdf 
  //// --------------------------------------
  const [selectedInvoice,setselectedInvoice]=React.useState([{sr:0,invoiceId:2201451,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:1,invoiceId:2201452,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:2,invoiceId:2201453,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:3,invoiceId:2201454,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:4,invoiceId:2201455,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:5,invoiceId:2201456,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:6,invoiceId:2201457,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:7,invoiceId:2201458,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:8,invoiceId:2201459,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:9,invoiceId:2201460,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:10,invoiceId:2201461,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:11,invoiceId:2201462,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:12,invoiceId:2201463,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:13,invoiceId:2201464,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},{sr:14,invoiceId:2201465,date:"05/Feb-23",patientName:"said husain",outstanding:612.00,Received:612.00},])
  const [selectInvoice,setselectInvoice]=React.useState(false)
  const [selected, setSelected] = React.useState({
    reports: []
  })
  

  const [rows, setRows] = React.useState([]);

  

  



    useEffect(() => {
        if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://invoicing.mpl-labs.pk/login"
        }
        if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://invoicing.mpl-labs.pk"
        }
        // fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
        //     setBranches(response)

        // })
        var vSearchStr={
          "User_ID" :localStorage.getItem('user_id'),
          "Menu_Option" :"Overall Create Booking"
      }
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCheckUserStatusActiveAccess",{
          method:"POST",
          'Content-Type':"application/json",
          body: JSON.stringify(vSearchStr)
        }).then(res=>res.json()).then((res)=>{
          console.log(res)
    
          if(res[0].Option_Status=="Authorized"){
           
          console.log("Yes Authorized")
         
            
          }else{
            alert("Sorry, You can't Authorized for this")
          
            window.location.href = "https://invoicing.mpl-labs.pk/"
          }
    
        })
        var obj22={
            branch_id:localStorage.getItem("branch_id")
          }
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetPanelsListBranch",
        {
               method:"POST",
        'Content-Type':"application/json",
        body: JSON.stringify(obj22)
        }
        ).then((res) => res.json()).then((response) => {
            console.log("GetPanelsListBranch"+JSON.stringify(response))
            // if(response.cred)
          response.push({
            Panel_Code: "Normal",
            Panel_Title: "Normal Patient"
          })
          setPanelList(response)
         
        })
       
        setLoading(false)


    }, [])
    Date.prototype.hhmm = function () {
        var mm = this.getMinutes();
        var hh = this.getHours();

        return [
            (hh > 9 ? '' : '0') + hh,
            (mm > 9 ? '' : '0') + mm
        ].join(':');
    };

    Date.prototype.ddmmyyy = function () {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [
            (dd > 9 ? '' : '0') + dd,
            (mm > 9 ? '' : '0') + mm,
            this.getFullYear()
        ].join('/');
    };
//my work 28-jan-2023
const SearchHandler=()=>{
    // alert("Date From "+from.toISOString()+"Date To"+to.toISOString()+"Panel"+panel+"Invoice ID"+invoiceID)
    // if(panel){
      // panel_code:"AR-01082",
       // fetch("http://206.84.161.44:8090/4DACTION/Receipt_PanelsInvoicesCallsUpWS", {
        // jab b call ho us se phly selected array empty ho jae
        // setSelected([])
        setRows([])

         var obj22={
           panel_code:panel,
           date_start:from.toISOString(),
           date_end:to.toISOString(),
           invoice_id:invoiceID
         }
         console.log("Paramter Of Api"+JSON.stringify(obj22))
       fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebInvoicesReceiptsListCallsUp",{
          method:"POST",
       'Content-Type':"application/json",
       body: JSON.stringify(obj22)
       }
       ).then((res) => res.json()).then((response) => {
         console.log("response Line 236 WebInvoicesReceiptsListCallsUp ",response)
         console.log("response Line 236 WebInvoicesReceiptsListCallsUp ",response.length)

         if(response.length){
         }
         else{
          alert("No Data Found...!")  
          setPanel('')
          setInvoiceID('')

          return    

         }
     
         console.log("response Line 236 WebInvoicesReceiptsListCallsUp ",JSON.stringify(response))
         var the_rows = []

         // for pdf use this one 
         // window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' +response[3].dms_id)  
         response.map((data) => {
         
console.log("data is "+JSON.stringify(data))
var obj = {
id:data.receipt_no,
date:(new Date(data.receipt_date).getDate()) + "/" + (new Date(data.receipt_date).getMonth() + 1) + "/" + (new Date(data.receipt_date).getFullYear()),
panelName:data.panel_name,
recevedValue:data.receipt_amount,
remarks:data.remarks,
invoice: 
<Button variant="contained" color="secondary"
style={{ backgroundColor: 'green', color: 'white' }} 
//  key={data.invoice_no}
disableElevation
onClick={() => {       
    var obj22={
      receipt_no:data.receipt_no,
      user_id:localStorage.getItem('user_id'),
       }
       console.log("obj22 is"+JSON.stringify(obj22))
     fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebInvoiceReceiptsCallsPrinting",
     {
            method:"POST",
     'Content-Type':"application/json",
     body: JSON.stringify(obj22)
     }
     ).then((res) => res.json()).then((response) => {
         console.log("WebDMS_ImageOrPDF_BLOBCallingUp"+JSON.stringify(response)) 

         var grouping=JSON.parse(response[0].Array_invoice_no)
         var TableRows=[]
         var totalRecieved=0
         var currentDateTime=date.toJSON()

 grouping.map((title,index)=>{
totalRecieved=totalRecieved+JSON.parse(response[0].Array_received)[index]
TableRows.push({
sn: JSON.parse(response[0].Array_seq)[index],
invoiceId: JSON.parse(response[0].Array_invoice_no)[index],
// date: JSON.parse(response[0].Array_invoice_date)[index],
date:(new Date(JSON.parse(response[0].Array_invoice_date)[index]).getDate()) + "/" + (new Date(JSON.parse(response[0].Array_invoice_date)[index]).getMonth() + 1) + "/" + (new Date(JSON.parse(response[0].Array_invoice_date)[index]).getFullYear()),
patientName: JSON.parse(response[0].Array_patient_name)[index],
outstanding: JSON.parse(response[0].Array_outstanding)[index],
received: JSON.parse(response[0].Array_received)[index],


})
         })
         console.log("TableRows are "+JSON.stringify(TableRows))
         console.log("TotalRecieved are "+JSON.stringify(totalRecieved))
         Promise.all(TableRows).then((values) => {
          var obj1 = {
            rec:response[0].receipt_no,
           date:(new Date(response[0].receipt_date).getDate()) + "/" + (new Date(response[0].receipt_date).getMonth() + 1) + "/" + (new Date(response[0].receipt_date).getFullYear()),
            // data:response[0].receipt_date,
            headingPrint:response[0].heading_print,
            tableData:TableRows,
            remarks:response[0].remarks,
            totalRecieved:Math.round(totalRecieved),
            user:response[0].printed_by_user,
            currentDateTime:(date.getDate()) + "/" + (date.getMonth() + 1) + "/" + (date.getFullYear()+" "+date.getHours()+":"+date.getMinutes())
          }
          console.log("kitny time gooma hai let check"+JSON.stringify(obj1))
          setSelected(obj1)
         })



         // picture_image
         // setSelectedReport(`${tesTmg}`+response.picture_image)
         //for pdf
        //  setSelectedReport(`${pdfurl}`+response.text_base64)
        //  console.log("image base64"+response.picture_image)
         setTimeout(() => {
           setselectInvoice(true)
         }, 500);
     }) 
           }}>
                         <  ReceiptIcon />

           </Button>
}
the_rows.push(obj)      
         })
         setRows(the_rows.reverse())
         setLoading(false)
         
       })
}
const handleChange = (event) => {
    setPanel(event.target.value)
  }


  //for dialogBox of invoice pdf
  
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


    return (
        <>
            <Paper className={classes.root}>
                <GridContainer style={{ padding: '2em'}}>
                    <GridItem xs={6} sm={6} md={6} lg={3}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="From"
                                format="dd/MM/yyyy"
                                value={from}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setFrom(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem>
                    {/* {
                        width < 600
                            ?
                            <GridItem xs={6} sm={6} md={0} lg={0}>

                            </GridItem> : ""
                    } */}

                    <GridItem xs={6} sm={6} md={6} lg={3}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="To"
                                format="dd/MM/yyyy"
                                value={to}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setTo(date)}
                            />
                        </MuiPickersUtilsProvider>

                    </GridItem>
                    {/* {
                        width < 600
                            ?
                            <GridItem xs={6} sm={6} md={0} lg={0}>

                            </GridItem> : ""
                    } */}

                    {
                        localStorage.getItem('org_branch_id') == '0' ?
                            <GridItem xs={12} sm={6} md={6} lg={3}  >
          <Autocomplete
            id="country-select"
            options={panelList}
            classes={{
              option: classes.option
            }}
            autoHighlight
             onChange={(event, newValue) => {
              if(newValue!=null){

                handleChange({
                  target:{
                    value:newValue.Panel_Code
                  }
                })
                
              }else{
                handleChange({
                  target:{
                    value:"Normal"
                  }
                })
              }
            }}
            getOptionLabel={(option) => option.Panel_Title}
            style={{
              // width:"10em"
            }}
            renderOption={(option) => <React.Fragment>{option.Panel_Title}</React.Fragment>}
            
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a Panel"
                variant="outlined"
                style={{
                  width:"15em"
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
                    {/* </Grid> */}
                            </GridItem>
                            : null
                    }
                    <GridItem xs={12} sm={6} md={6} lg={3}  >

                    <TextField
          // required
          id="outlined-required"
          label="Invoice No"
          variant="outlined"
          value={invoiceID}
          onChange={(e) => {
            // setRows([])
            // setPrintiDicator(false)
            setInvoiceID(e.target.value)
          }}
        />
              

                    </GridItem>
                </GridContainer>
                {/* <div style={{
        flexDirection: "row",
        flex: 1,
        textAlign: 'center',
        padding: "2em"
      }}> */}
        <GridContainer style={{ paddingBottom: '2em',justifyContent:"center",alignItem:"center",display:"flex"}}>
        
       

<Button style={{marginLeft:15}} variant="contained" color="primary" disableElevation disabled={loading}  onClick={() => {
            
            SearchHandler()
           
          }}
  
                          >
                              Search<SearchIcon />
                          </Button>
      
      {/* </div> */}
      </GridContainer>


                {loading && localStorage.getItem('branch_id') != '0' ?
                    <center>
                        <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
                    </center>
                    :
                    <>
                        <GridContainer>
                     
                          <GridItem md={12} style={{}} >
                          <MaterialTable
                          style={{marginLeft:25}}
        title="Payment Receipt Record"
        data={rows}
        columns={[
          { title: 'Receipt ID', field: 'id' },
          { title: 'Date', field: 'date' },
          {title: 'Panel`s Name', field:"panelName"},
          { title: 'Receved Value', field: 'recevedValue' },
          { title: 'Remarks', field: 'remarks' },
          { title: 'Invoice', field: 'invoice' }

        ]}
        // maxBodyHeight={"50em"}

        options={{
          // selection: true,
          paging:true,
          minBodyHeight:40,
          // showSelectAllCheckbox:SelectionControl
        }}

       
      />
                          </GridItem>
                          
                        

                        </GridContainer>
                       
                    </>
                }


            </Paper>
   {/* ////////////////////// Receipt and data pass to this modal selectedReceipt (that are upadte in receipt 297 useEffect and search wala b same hoga)/////////////////////////// */}
  {/* aik pdf open kren to page hang ho rha tha ye condition lgany se thek ho gya */}
  {selectInvoice && 
   <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={selectInvoice}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setselectInvoice(false)
          // setSelectedReceipt({
          //   reports: []
          // })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Invoice</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          
           
          {
            selectedInvoice[0].sr != undefined && selectInvoice ?
              <center> <React.Fragment key={"sqq"} id>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Invoice invoice={selected} />
                </PDFViewer>
              </React.Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setselectInvoice(false)
              // setSelectedReceipt({
              //   reports: []
              // })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
    }
        </>
    );
}
