import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import HelpIcon from '@material-ui/icons/Help';
import { isMobile } from 'react-device-detect';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';

import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import Invoice from './PDF/Invoice.js'

import Simple_Invoice from './PDFSimple/Invoice.js'
import Sample_Invoice from './SampleInvoice/Invoice.js'
// 1st-april 2023 for Outsource service order
import Sample_InvoiceNew from './SampleInvoiceNew/Invoice.js'


import Receipt from './Receipt/Invoice.js'

import LabOrder from './Lab_Order/Invoice.js'


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import RefreshIcon from '@material-ui/icons/Refresh';

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';


import CovidTemplate from './Covid_PDF/CovidTemplate'


import OfficialCovidTemplate from './Official_Template/OfficialCovidTemplate'




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const columns =
  [
  { id: 'invoice_no', label: 'Invoice ID' },
  { id: 'patient_id', label: 'Patient ID' },
  { id: 'patient_name', label: 'Patient Name' },
  { id: 'branch_name', label: 'Branch' },
  { id: 'tests_included', label: 'Tests' },
  { id: 'mobile_no', label: 'Mobile No' },
  { id: 'remarks', label: 'Remarks' },
  //for Edit Patient 11-jan-2023
  
  { id: 'patient_invoice', label: 'Printed Invoice' },
  { id: 'lab_order', label: 'Lab Order' },
  { id: 'simple_invoice', label: 'Simple Invoice' },
  { id: 'sample_invoice', label: 'Service Order' },
  // 1st-april-2023 for Outsource service order
  { id: 'sample_invoiceNew', label: 'Outsource Service Order' },

  { id: 'barcode', label: <p style={{
    textAlign:'center'
  }}>Barcode (Branches)</p> },
  { id: 'receipt', label: 'Receipt' },
   //09-march-2023
   {id:'barcodeSticker' , label:'Print Report Sticker'},

  ]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
  return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(seconds) {

  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return addZero(hours) + ':' + addZero(minutes);
}

export default function Lab_Tests() {
  const classes = useStyles();
  var date = new Date()
  var temp_role = localStorage.getItem('role')
  date.setDate(date.getDate() - 2);

  const [selected, setSelected] = React.useState({
    reports: []
  })

  const [OpenCovidTemplate, setCovidTemplate] = React.useState(false)

  const [OpenOfficialCovidTemplate, setOfficialCovidTemplate] = React.useState(false)

  const [patient_invoice, setPatientInvoice] = React.useState(false)


  const [simple_invoice, setSimpleInvoice] = React.useState(false)

  const [sample_invoice, setSampleInvoice] = React.useState(false)
  // 1st-april-2023 for Outsource service order
  const [sample_invoiceNew, setSampleInvoiceNew] = React.useState(false)



  const [from, setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel, setPanel] = React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  //for Edit Patient 11-jan-2023
  


  const [selectedCovid, setSelectedCovid] = React.useState({
    reports: []
  })
  const [selectedOfficialCovidReport, setSelectedOfficialCovidReport] = React.useState({
    reports: []
  })


  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)

  const [covidButton, setCovidButton] = React.useState(false)

  const [panelList, setpanelList] = React.useState([])
  const [laborder, setLabOrder] = React.useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches, setBranches] = React.useState([])
  const [branch, setBranch] = React.useState("")
  const [selectBranch, setSelectBranch] = React.useState([])
  const [selectOrders, setSelectedOrders] = React.useState([])
  const [state, setState] = React.useState({
    status: 'All',
  });

  //// --------------------------------------
  const [selectedReceipt,setSelectedReceipt]=React.useState("")
  const [selectReceiptOpen,setSelectReceiptOpen]=React.useState(false)

  var svgString2Image = (svgString, width, height, format, callback) => {

  if(svgString.trim()==""){
    callback('')
    return ""
  }

    var svgStringFormat = window.atob(svgString)
    var temp = svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL('image/' + format);
      // pass png data URL to callbac
      callback(pngData)
    }; // end async
    image.src = svgData

  }

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const numberWithCommas= (x)=> {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://invoicing.mpl-labs.pk/login"
    }
    if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://invoicing.mpl-labs.pk"
    }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
      setBranches(response)

    })
    var patient_id = localStorage.getItem('patient_id')
    var role = localStorage.getItem('role')

    var from = new Date();


    var branchID = localStorage.getItem('branch_id')

    var to = new Date();
    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
    
    var url = "https://reports.mpl-labs.pk:8443/4DACTION/InvoiceListCalledByPrintPortal"
   
    to.setHours(15)
    from.setHours(15)
    fetch(url,{
      method:"POST",
      body:JSON.stringify({
        branch_id:JSON.parse(branchID),
        date_start:from.toISOString(),
        date_end:to.toISOString()
      })
    }).then((res) => res.json())
      .then((result) => {
       console.log("Invoice Record 283 :",result)
        var the_rows = []
        //################ response of Api result Map (data) start ####################
        result.map((data) => {
          var br_title = ""

          if (data.branch_name == "METROPOLE") {
            br_title = "Islamabad"
          } else {
            br_title = data.branch_name.split('MPL,')[1]
          }
          //ye wala object niche the-rows me push krty hn aur table me show krty hn if 100 vale in result then 100 obj will be push on the rows
          var obj = {
            ...data,
            tests_included:data.tests_included.includes('^') ? data.tests_included.replaceAll('^','(').replaceAll('|',"),")+")" : data.tests_included.replaceAll('^','(').replaceAll('|',"),") ,
            receipt:  <center>
              {/* ye Receipt wala Button hai */}
            <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
              var temp = {
                Invoice_No:data.invoice_no+""
              }
              fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                method: 'POST',
                'Content-Type': "application/json",
                body: JSON.stringify(temp)
              }).then((res) => res.json()).then(async (record) => {
                console.log("Invoice Record when click on Receipt 307 : ",record)

                svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                  var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                  var print_date = new Date().ddmmyyy()
                  var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                  // Array Test Code me short name hain Test k and Array Test Name me full name hn test k
                  var orders = JSON.parse(record[0].Array_Test_Code)

                  var counter = -1

                  var groupBox={}

                  var TableRows=[]

                  var grouping=JSON.parse(record[0].ArrGroupingTitle)

                  var fee=0
                  var disc=0
                  var net_value=0
                 // Array group Title jo grouping ko dya hai us pe map lg rha 
                  grouping.map((title,index)=>{
                    if(title.trim()==""){
                      return
                     }
                     fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                     disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                     net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

                    groupBox[title]={
                      sn: index+1,
                      test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                      fee: numberWithCommas((fee).toFixed(2)),
                      disc: numberWithCommas((disc).toFixed(2)),
                      net_value: numberWithCommas((net_value).toFixed(2)),
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No
                    }
                  })

                  Object.keys(groupBox).map((key)=>{
                    TableRows.push(groupBox[key])

                  })
                  console.log(groupBox)
                  // orders me arrayTestCode(jo short form me test k code hn) dye hn opr es pe map lg rha if 6 short code then 6 time map run and calculate fee ,disc,netvalue etc
                  var up_order = await orders.map((item,index) => {
                    
                    if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
                    
                      return
                     }
                    // yhan pe Fee calculate ho rhi hai fee ki array hai ArrPriceActual record ka 0 fix hai ku k record[0] he data hai and [index ]aage chalta jae ga ku k us k under array hn wo one by one fill krni hain
                    fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                    //yhan dicount count ho rha hai PriceActual me se Payable minus kr rhe hn if payable same then no discount
                    disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                  //net value jo pay krni hai client ne
                    net_value=JSON.parse(record[0].Array_Payable)[index] + net_value
                   //count ki value -1 se init hai
                    counter++
                    //tableRow aik array init hai
                    TableRows.push({
                      //sn means serial number
                      sn: counter + 1,
                      //array ka test name testTitle me dal den one by one
                      test_title: JSON.parse(record[0].Array_Test_Name)[index],
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                      //numberWithCommas TotalfeeCalculate ki value me commas dalny k lye toFixed . ka bad two 0 k lye 1,000.00
                      fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
                      //total disc find kr rhe 
                      disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                     //total net value find kr rhe
                      net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                      //for branch name
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No
                    
                    })
                    // tableRow obj end
                    return ""

                  })
                  //orders.map end sari value up_order me update hui hn

                  Promise.all(up_order).then((values) => {
                    var obj1 = {
                      //record is a response of API
                      branch: record[0].Branch_Name,
                      //barcode SvgStringToImage se update ho k aaya
                      barcode: barcode,
                      name: record[0].Patient_Name,
                      age_gender: record[0].Age_Gender,
                      password: record[0].Password,
                      patient_id: record[0].Login_Name,
                      Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                       Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                      print_time: formatAMPM(new Date()),
                      //printDate update kiya hai oper current date se
                      print_date: print_date,
                      Ref_By: record[0].Ref_By,
                      Ref_No: record[0].Ref_No,

                      BranchAddress:record[0].BranchAddress,
                      BranchPhone:record[0].BranchPhone,
                      BranchTiming:record[0].BranchTiming,
                      BranchTitle:record[0].BranchTitle,
                      BranchExcLetterhead:record[0].BranchExcLetterhead,


                      Invoice_Remarks: record[0].Invoice_Remarks,
                      Patient_Comments: record[0].Patient_Comments,
                      invoice_time: msToTime(record[0].Invoice_Time),
                      Entitlement_Name: record[0].Entitlement_Name,
                      //invoice date oper update kr di
                      invoice_date: invoice_date,
                      //disc and fee oper update ki thi 
                      Discount_Total: numberWithCommas((disc).toFixed(2)),
                      Invoice_Value: numberWithCommas((fee).toFixed(2)),
                      User_Name: record[0].user_name,
                      Ticket_No: record[0].Ticket_No,
                      invoice_id: record[0].Invoice_ID,
                      contact: record[0].Mobile_No,
                      Panel_Name: record[0].Panel_Name,
                      passport_no: record[0].Passport_No,
                      //flight date b oper update ki thi
                      flight_date: flight_date,
                      flight_no: record[0].Flight_No,
                      flight_time: msToTime(record[0].Flight_Time),
                      toAddress:record[0].To_Address,
                      //TableRows oper obj bna k update ki thi us me total fee,disc,netPrice etc dali thi 373 
                      reports: TableRows,
                      //ye 3no already response me aa rhi hn value
                      Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
                      Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
                      Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
                      cnic: record[0].CNIC,
                   
                    }
                    //selectReceipt ki state ko update kr dya
                    setSelectedReceipt(obj1)

                    setTimeout(() => {
                      //end selectReceiptOpen ko true kr rhy hn
                      //2375 me modal ko value pass ho jae gi aur Reciept ka Modal open ho jae ga 
                      setSelectReceiptOpen(true)
            
                    }, 500);
                  })

                })

              })

            }}>
              <  ReceiptIcon />
            </Button>
          </center>
          ,
            patient_invoice: <center>
            <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
              var temp = {
                Invoice_No:data.invoice_no+""
              }
              fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                method: 'POST',
                'Content-Type': "application/json",
                body: JSON.stringify(temp)
              }).then((res) => res.json()).then(async (record) => {
                console.log("Invoice Record when click on Printed Invoice button response of api :",record)
                svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                  svgString2Image(record[0].FBR_QR_Code, 800, 600, 'png', async (FBR_QR_Code) => {
                    console.log('Test')
                  var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                  var print_date = new Date().ddmmyyy()
                  var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                  var orders = JSON.parse(record[0].Array_Test_Code)

                  var counter = -1

                  var groupBox={}

                  var TableRows=[]

                  var grouping=JSON.parse(record[0].ArrGroupingTitle)

                  var fee=0
                  var disc=0
                  var net_value=0

                  grouping.map((title,index)=>{
                    if(title.trim()==""){
                      return
                     }
                     fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                     disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                     net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

                    groupBox[title]={
                      sn: index+1,
                      test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                      fee: numberWithCommas((fee).toFixed(2)),
                      disc: numberWithCommas((disc).toFixed(2)),
                      net_value: numberWithCommas((net_value).toFixed(2)),
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No,
                           
                    }
                  })

                  Object.keys(groupBox).map((key)=>{
                    TableRows.push(groupBox[key])

                  })
                  console.log(groupBox)

                  var up_order = await orders.map((item,index) => {
                    
                    if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
                    
                      return
                     }

                    fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                    disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                    net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

                    counter++
                    TableRows.push({
                      sn: counter + 1,
                      test_title: JSON.parse(record[0].Array_Test_Name)[index],
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                      fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
                      disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                      net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No,
                      //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                    
                    })
                    return ""

                  })

                  Promise.all(up_order).then((values) => {
                    var obj1 = {
                      branch: record[0].Branch_Name,
                      barcode: barcode,
                      name: record[0].Patient_Name,
                      age_gender: record[0].Age_Gender,
                      password: record[0].Password,
                      patient_id: record[0].Login_Name,
                      Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                       Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                      print_time: formatAMPM(new Date()),
                      print_date: print_date,
                      Ref_By: record[0].Ref_By,
                      Ref_No: record[0].Ref_No,
                      // FBR
                      pos:FBR_QR_Code,
                      pos_id:record[0].FBR_ID,

                      BranchAddress:record[0].BranchAddress,
                      BranchPhone:record[0].BranchPhone,
                      BranchTiming:record[0].BranchTiming,
                      BranchTitle:record[0].BranchTitle,
                      BranchExcLetterhead:record[0].BranchExcLetterhead,


                      Invoice_Remarks: record[0].Invoice_Remarks,
                      Patient_Comments: record[0].Patient_Comments,
                      invoice_time: msToTime(record[0].Invoice_Time),
                      Entitlement_Name: record[0].Entitlement_Name,
                      invoice_date: invoice_date,
                      Discount_Total: numberWithCommas((disc).toFixed(2)),
                      Invoice_Value: numberWithCommas((fee).toFixed(2)),
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      Ticket_No: record[0].Ticket_No,
                      invoice_id: record[0].Invoice_ID,
                      contact: record[0].Mobile_No,
                      Panel_Name: record[0].Panel_Name,
                      passport_no: record[0].Passport_No,
                      flight_date: flight_date,
                      flight_no: record[0].Flight_No,
                      flight_time: msToTime(record[0].Flight_Time),
                      toAddress:record[0].To_Address,
                      reports: TableRows,
                      Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
                      Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
                      Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
                      // cnic: "",

                      // for new MR panel 3-JAN-2023
                      if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
                    //  if_panelMR_cnic_father:true,
     // cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
     father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null"
    //  father_husband:"khalid mehmood"
    

                    }
                    setSelected(obj1)

                    setTimeout(() => {
                      setPatientInvoice(true)

                    }, 500);
                  })

                })
              })


              })

            }}>
              <  ReceiptIcon />
            </Button>
          </center>
            ,
            simple_invoice: <center>
            <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
              var temp = {
                Invoice_No:data.invoice_no+""
              }
              fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                method: 'POST',
                'Content-Type': "application/json",
                body: JSON.stringify(temp)
              }).then((res) => res.json()).then(async (record) => {
                svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                  svgString2Image(record[0].FBR_QR_Code, 800, 600, 'png', async (FBR_QR_Code) => {
                  var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                  var print_date = new Date().ddmmyyy()
                  var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                  var orders = JSON.parse(record[0].Array_Test_Code)

                  var counter = -1

                  var groupBox={}

                  var TableRows=[]

                  var grouping=JSON.parse(record[0].ArrGroupingTitle)

                  var fee=0
                  var disc=0
                  var net_value=0

                  grouping.map((title,index)=>{
                    if(title.trim()==""){
                      return
                     }
                     fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                     disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                     net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

                    groupBox[title]={
                      sn: index+1,
                      test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                      fee: numberWithCommas((fee).toFixed(2)),
                      disc: numberWithCommas((disc).toFixed(2)),
                      net_value: numberWithCommas((net_value).toFixed(2)),
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No
                    }
                  })

                  Object.keys(groupBox).map((key)=>{
                    TableRows.push(groupBox[key])

                  })
                  console.log(groupBox)

                  var up_order = await orders.map((item,index) => {
                    
                    if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
                    
                      return
                     }

                    fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                    disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                    net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

                    counter++
                    TableRows.push({
                      sn: counter + 1,
                      test_title: JSON.parse(record[0].Array_Test_Name)[index],
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                      fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
                      disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                      net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No,
                                          //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                    
                    })
                    return ""

                  })

                  Promise.all(up_order).then((values) => {
                    var obj1 = {
                      branch: record[0].Branch_Name,
                      barcode: barcode,
                      name: record[0].Patient_Name,
                      age_gender: record[0].Age_Gender,
                      password: record[0].Password,
                      patient_id: record[0].Login_Name,
                      Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                       Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                      print_time: formatAMPM(new Date()),
                      print_date: print_date,
                      Ref_By: record[0].Ref_By,
                      Ref_No: record[0].Ref_No,

                      BranchAddress:record[0].BranchAddress,
                      BranchPhone:record[0].BranchPhone,
                      BranchTiming:record[0].BranchTiming,
                      BranchTitle:record[0].BranchTitle,
                      BranchExcLetterhead:record[0].BranchExcLetterhead,
                      pos:FBR_QR_Code,
                      pos_id:record[0].FBR_ID,

                      Invoice_Remarks: record[0].Invoice_Remarks,
                      Patient_Comments: record[0].Patient_Comments,
                      invoice_time: msToTime(record[0].Invoice_Time),
                      Entitlement_Name: record[0].Entitlement_Name,
                      invoice_date: invoice_date,
                      Discount_Total: numberWithCommas((disc).toFixed(2)),
                      Invoice_Value: numberWithCommas((fee).toFixed(2)),
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      Ticket_No: record[0].Ticket_No,
                      invoice_id: record[0].Invoice_ID,
                      contact: record[0].Mobile_No,
                      Panel_Name: record[0].Panel_Name,
                      passport_no: record[0].Passport_No,
                      flight_date: flight_date,
                      flight_no: record[0].Flight_No,
                      flight_time: msToTime(record[0].Flight_Time),
                      toAddress:record[0].To_Address,
                      reports: TableRows,
                      Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
                      Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
                      Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
                        // cnic: "37505-1287363-9",

                      // for new MR panel 3-JAN-2023
                      if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
                    //  if_panelMR_cnic_father:true,
     // cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
    //  father_husband:record[0].if_panelMR_cnic_father?record[0].father_husband:"null"
    father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null"

    //  father_husband:"Ashraf Mehmood"

                    }
                    setSelected(obj1)

                    setTimeout(() => {
                      setSimpleInvoice(true)

                    }, 500);
                  })

                })
              })


              })

            }}>
              <  ReceiptIcon />
            </Button>
          </center>
            ,
            sample_invoice: <center>
              {/* ye service Order hai  */}
            <Button variant="contained" style={{ backgroundColor: 'blue', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
              var temp = {
                Invoice_No:data.invoice_no+""
              }
              fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                method: 'POST',
                'Content-Type': "application/json",
                body: JSON.stringify(temp)
              }).then((res) => res.json()).then(async (record) => {
                svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                  var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                  var print_date = new Date().ddmmyyy()
                  var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                  var orders = JSON.parse(record[0].Array_Test_Code)

                  var counter = -1

                  var groupBox={}

                  var TableRows=[]

                  var grouping=JSON.parse(record[0].ArrGroupingTitle)

                  var fee=0
                  var disc=0
                  var net_value=0

                  grouping.map((title,index)=>{
                    if(title.trim()==""){
                      return
                     }
                     fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                     disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                     net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

                    groupBox[title]={
                      sn: index+1,
                      test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                      fee: numberWithCommas((fee).toFixed(2)),
                      disc: numberWithCommas((disc).toFixed(2)),
                      net_value: numberWithCommas((net_value).toFixed(2)),
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No
                    }
                  })

                  Object.keys(groupBox).map((key)=>{
                    TableRows.push(groupBox[key])

                  })
                  console.log(groupBox)

                  var up_order = await orders.map((item,index) => {
                    
                    if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
                    
                      return
                     }

                    fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                    disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                    net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

                    counter++
                    TableRows.push({
                      sn: counter + 1,
                      test_title: JSON.parse(record[0].Array_Test_Name)[index],
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                      fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
                      disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                      net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No,
                       //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")

                    
                    })
                    return ""

                  })

                  Promise.all(up_order).then((values) => {
                    var obj1 = {
                      branch: record[0].Branch_Name,
                      barcode: barcode,
                      name: record[0].Patient_Name,
                      age_gender: record[0].Age_Gender,
                      password: record[0].Password,
                      patient_id: record[0].Login_Name,
                      Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                       Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                      print_time: formatAMPM(new Date()),
                      print_date: print_date,
                      Ref_By: record[0].Ref_By,
                      Ref_No: record[0].Ref_No,

                      BranchAddress:record[0].BranchAddress,
                      BranchPhone:record[0].BranchPhone,
                      BranchTiming:record[0].BranchTiming,
                      BranchTitle:record[0].BranchTitle,
                      BranchExcLetterhead:record[0].BranchExcLetterhead,


                      Invoice_Remarks: record[0].Invoice_Remarks,
                      Patient_Comments: record[0].Patient_Comments,
                      invoice_time: msToTime(record[0].Invoice_Time),
                      Entitlement_Name: record[0].Entitlement_Name,
                      invoice_date: invoice_date,
                      Discount_Total: numberWithCommas((disc).toFixed(2)),
                      Invoice_Value: numberWithCommas((fee).toFixed(2)),
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      Ticket_No: record[0].Ticket_No,
                      invoice_id: record[0].Invoice_ID,
                      contact: record[0].Mobile_No,
                      Panel_Name: record[0].Panel_Name,
                      passport_no: record[0].Passport_No,
                      flight_date: flight_date,
                      flight_no: record[0].Flight_No,
                      flight_time: msToTime(record[0].Flight_Time),
                      toAddress:record[0].To_Address,
                      reports: TableRows,
                      Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
                      Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
                      Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
                                // cnic: "",

                      // for new MR panel 3-JAN-2023
                      if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
                    //  if_panelMR_cnic_father:true,
    //  cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
    //  father_husband:record[0].if_panelMR_cnic_father?record[0].father_husband:"null"
    father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null"

    //  father_husband:"null"

                    }
                    setSelected(obj1)

                    setTimeout(() => {
                      setSampleInvoice(true)

                    }, 500);
                  })

                })


              })

            }}>
              <  ReceiptIcon />
            </Button>
          </center>
            ,
            sample_invoiceNew: <center>
              {/* ye service Order hai  */}
            <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
              var temp = {
                Invoice_No:data.invoice_no+""
              }
              fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                method: 'POST',
                'Content-Type': "application/json",
                body: JSON.stringify(temp)
              }).then((res) => res.json()).then(async (record) => {
                svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                  var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                  var print_date = new Date().ddmmyyy()
                  var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                  var orders = JSON.parse(record[0].Array_Test_Code)

                  var counter = -1

                  var groupBox={}

                  var TableRows=[]

                  var grouping=JSON.parse(record[0].ArrGroupingTitle)

                  var fee=0
                  var disc=0
                  var net_value=0

                  grouping.map((title,index)=>{
                    if(title.trim()==""){
                      return
                     }
                     fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                     disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                     net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

                    groupBox[title]={
                      sn: index+1,
                      test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                      fee: numberWithCommas((fee).toFixed(2)),
                      disc: numberWithCommas((disc).toFixed(2)),
                      net_value: numberWithCommas((net_value).toFixed(2)),
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No
                    }
                  })

                  Object.keys(groupBox).map((key)=>{
                    TableRows.push(groupBox[key])

                  })
                  console.log(groupBox)

                  var up_order = await orders.map((item,index) => {
                    
                    if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
                    
                      return
                     }

                    fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                    disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                    net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

                    counter++
                    TableRows.push({
                      sn: counter + 1,
                      test_title: JSON.parse(record[0].Array_Test_Name)[index],
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                      fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
                      disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                      net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No,
                       //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")

                    
                    })
                    return ""

                  })

                  Promise.all(up_order).then((values) => {
                    var obj1 = {
                      branch: record[0].Branch_Name,
                      barcode: barcode,
                      name: record[0].Patient_Name,
                      age_gender: record[0].Age_Gender,
                      password: record[0].Password,
                      patient_id: record[0].Login_Name,
                      Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                       Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                      print_time: formatAMPM(new Date()),
                      print_date: print_date,
                      Ref_By: record[0].Ref_By,
                      Ref_No: record[0].Ref_No,

                      BranchAddress:record[0].BranchAddress,
                      BranchPhone:record[0].BranchPhone,
                      BranchTiming:record[0].BranchTiming,
                      BranchTitle:record[0].BranchTitle,
                      BranchExcLetterhead:record[0].BranchExcLetterhead,


                      Invoice_Remarks: record[0].Invoice_Remarks,
                      Patient_Comments: record[0].Patient_Comments,
                      invoice_time: msToTime(record[0].Invoice_Time),
                      Entitlement_Name: record[0].Entitlement_Name,
                      invoice_date: invoice_date,
                      Discount_Total: numberWithCommas((disc).toFixed(2)),
                      Invoice_Value: numberWithCommas((fee).toFixed(2)),
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      Ticket_No: record[0].Ticket_No,
                      invoice_id: record[0].Invoice_ID,
                      contact: record[0].Mobile_No,
                      Panel_Name: record[0].Panel_Name,
                      passport_no: record[0].Passport_No,
                      flight_date: flight_date,
                      flight_no: record[0].Flight_No,
                      flight_time: msToTime(record[0].Flight_Time),
                      toAddress:record[0].To_Address,
                      reports: TableRows,
                      Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
                      Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
                      Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
                                // cnic: "",

                      // for new MR panel 3-JAN-2023
                      if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
                    //  if_panelMR_cnic_father:true,
    //  cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
    //  father_husband:record[0].if_panelMR_cnic_father?record[0].father_husband:"null"
    father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null"

    //  father_husband:"null"

                    }
                    setSelected(obj1)

                    setTimeout(() => {
                      setSampleInvoiceNew(true)

                    }, 500);
                  })

                })


              })

            }}>
              <  ReceiptIcon />
            </Button>
          </center>
            ,
            barcode: <center>
            <Button variant="contained" style={{ backgroundColor: 'white', color: 'black' }} key={data.invoice_no} disableElevation onClick={() => {
            
                var obj1 = {
                  sample_id: data.invoice_no,
                  age_gender: "",
                  test_title: "Nasal Swab/Nasal Secretions",
                  date: new Date().ddmmyyy(),
                  mpl_id: data.invoice_no,
                  name: data.patient_name,
                  patient_id: data.patient_id,
                  time: formatAMPM(new Date())

                }
                console.log("ref no is #######",JSON.stringify(data))
                // ye commit tha abi on kr dya hai 5 jan ko nxt update me done ho jae ga
                var url = "http://localhost:1000/GETBarcode"
                fetch(url, {
                  method: "POST",
                  body: JSON.stringify(obj1),
                  headers: {
                    'Content-Type': "application/json"
                  }
                }).then(() => {
                  alert("Print Request Sent")
                })
             
            }} >
              Barcode
          </Button>
          </center>
          ,
            lab_order: <center>
            <Button variant="contained" style={{ backgroundColor: 'purple', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
              var temp = {
                Invoice_No:data.invoice_no+""
              }
              fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                method: 'POST',
                'Content-Type': "application/json",
                body: JSON.stringify(temp)
              }).then((res) => res.json()).then(async (record) => {
                console.log("WebInvoiceAndLabOrder "+JSON.stringify(record))
                svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                  var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                  var print_date = new Date().ddmmyyy()
                  var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                  var orders = JSON.parse(record[0].Array_Test_Code)
                  var counter = -1
              
                  var up_order = await orders.map((item) => {
                    counter++
                    return {
                      sn: counter + 1,
                      // ye orignal thi niche wali
                      // test_title: JSON.parse(record[0].Array_Test_Name)[counter]+ ( JSON.parse(record[0].ArrGroupingTitle)[counter]==""? "" : "\n - "+ JSON.parse(record[0].ArrGroupingTitle)[counter] ) ,
                      //es tran b ho jati hai es se ye hoga just aik dfa he aae ga groupTitle but wo sr no 1 k test k under aae ga opr wali line se her test k sth group title aa rha tha
                      // test_title: JSON.parse(record[0].Array_Test_Name)[counter]+ ( JSON.parse(record[0].ArrGroupingTitle)[counter]==""? "" :counter<1? "\n - "+ JSON.parse(record[0].ArrGroupingTitle)[counter]:"" ) ,
                      //add group title seprate 1801348 9-jan-2023 by sir najam updation
                     
                      test_title: JSON.parse(record[0].Array_Test_Name)[counter] ,
                      group_title: JSON.parse(record[0].ArrGroupingTitle)[counter]==""? "" :JSON.parse(record[0].ArrGroupingTitle)[counter] ,
                     
                     
                      reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[counter],
                      fee: (JSON.parse(record[0].Array_Price)[counter]).toFixed(2),
                      disc: JSON.parse(record[0].Array_Discount)[counter].toFixed(2),
                      net_value: JSON.parse(record[0].Array_Payable)[counter].toFixed(2),
                      branch: record[0].Branch_Name,
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      name: record[0].Patient_Name,
                      passport: record[0].Passport_No,
                      //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                      
                    
                    }


                  })

                  Promise.all(up_order).then((values) => {
                    var obj1 = {
                      branch: record[0].Branch_Name,
                      barcode: barcode,
                      name: record[0].Patient_Name,
                      age_gender: record[0].Age_Gender,
                      password: record[0].Password,
                      patient_id: record[0].Login_Name,
                      print_time: formatAMPM(new Date()),
                      print_date: print_date,
                      Ref_By: record[0].Ref_By,
                      Ref_No: record[0].Ref_No,
                      Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                      Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                      Invoice_Remarks: record[0].Invoice_Remarks,
                      Patient_Comments: record[0].Patient_Comments,
                      invoice_time: msToTime(record[0].Invoice_Time),
                      Entitlement_Name: record[0].Entitlement_Name,
                      invoice_date: invoice_date,
                      Discount_Total: record[0].Discount_Total.toFixed(2),
                      Invoice_Value: record[0].Invoice_Value.toFixed(2),
                      User_Name: record[0].user_name,
                      cnic: record[0].CNIC,
                      Ticket_No: record[0].Ticket_No,
                      invoice_id: record[0].Invoice_ID,
                      contact: record[0].Mobile_No,
                      Panel_Name: record[0].Panel_Name,
                      passport_no: record[0].Passport_No,
                      Destination:record[0].Destination,
                      Airline:record[0].Airline,
                      flight_date: flight_date,
                      flight_no: record[0].Flight_No,
                      flight_time: msToTime(record[0].Flight_Time),
                      toAddress:record[0].To_Address,
                      reports: values,
                      Invoice_Payable: record[0].Invoice_Payable.toFixed(2),
                      Invoice_Received: record[0].Invoice_Received.toFixed(2),
                      Invoice_Balance: record[0].Invoice_Balance.toFixed(2),
                          //  cnic: "37505-1287363-9",

                      // for new MR panel 3-JAN-2023
                      if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
                    //  if_panelMR_cnic_father:true,
    //  cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
    //  father_husband:record[0].if_panelMR_cnic_father?record[0].father_husband:"null"
    father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null",

    //  father_husband:"Ashraf Mehmood"
    // this is for row adding by sir najam waiting for sir kamran 10-jan-2023 and change in InvoiveItemTableRow 69
    // parameterData:["Bilirubin Total,ALT (SGPT),AST (SGOT),Alkaline Phosphatase (ALP),Gamma GT"]
    

                    }
                    setSelectedOrders(obj1)
                    setTimeout(() => {
                      setLabOrder(true)

                    }, 1000);
                  })

                })


              })
        //Check
                //      var objN={
                //   // test_id: newValue.Test_ID
                //   test_id:"UR-001-T-0005"
                // }

                // var vSearchStrN = JSON.stringify(objN)
                // console.log("New Api Test parameter send to API In 2406 ######################## "+vSearchStrN)

                // var url = "https://reports.mpl-labs.pk:8443/4DACTION/TestS_AUX_LinesCallsWebServer"
                
                // fetch(url, {
                //   method: "POST",
                //   'Content-Type': "application/json",
                //   body: vSearchStrN
                //   }).then((res) => res.json()).then(async (responseN) => {
                //     console.log("New Api Test In 2414 ######################## "+responseN)
                //   })
                //               var objN={
  //                  test_id:newValue.Test_ID
  //                 }
  //                 console.log("New Api Test In 2414 ######################## "+JSON.stringify(objN))

  //               fetch("https://reports.mpl-labs.pk:8443/4DACTION/TestS_AUX_LinesCallsWebServer", {
  //     method:"POST",
  //     body:JSON.stringify(objN)
  // }).then(res=>res.json()).then((response)=>{
  //                   console.log("New Api Test In 2414 ######################## "+ JSON.stringify(response))
                    
  //                 { response.map((row) => {
  //                   console.log("map response"+JSON.stringify(row))
  //                   testDetail.push(row.test_title)

  //                 })}
                //2nd try
       
                  //End Check

            }}>
              <  ReceiptIcon />
            </Button>
          </center>,
   //09-march-2023
   barcodeSticker:<center>
   <Button variant="contained" style={{backgroundColor:'white', color:'black'}} key={data.Invoice_ID} disableElevation onClick={()=>{
     var temp = {
       Invoice_No:data.invoice_no+""
     }
     fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
       method: 'POST',
       'Content-Type': "application/json",
       body: JSON.stringify(temp)
     }).then((res) => res.json()).then(async (record) => {
       // console.log("/Web_InvoiceAndLabOrderPrinting record"+JSON.stringify(record))
         var obj1={
           name:data.patient_name,
           age_gender:record[0].Age_Gender,
           mpl_id:data.invoice_no,
           ref_by:record[0].Ref_By,
           date:new Date().ddmmyyy(),
           study:data.tests_included
 
         }
         console.log(obj1)
         var url="http://localhost:1000/ReportSticker"
         fetch(url,{
           method:"POST",
           body:JSON.stringify(obj1),
           headers:{
             'Content-Type':"application/json"
           }
         }).then(()=>{
           alert("Print Request Sent")
         })
       })
     
     }}
     
     >
 Report Sticker
     </Button>
     </center>,
 

          }
          the_rows.push(obj)

        })
        //################ response of Api result Map (data) end ####################
     
        setRows(the_rows)
        setOrg_Rows(the_rows)
        setLoading(false)
      }).catch(()=>{
        setLoading(false)
        setPage(0)
      })
    
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
      .then((result) => {
        setpanelList(result)
      })
    if (localStorage.getItem('org_branch_id') == '0') {
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
        setBranches(response)
        var obj = {}
        response.map((item) => {
          obj[item.BranchID] = item.BranchName
        })
        setSelectBranch(obj)

        setBranch(localStorage.getItem('branch_id'))
      })
    }


  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <>
      <Paper className={classes.root}>
        <div style={{ padding: '1em', textAlign: 'center' }}>
          {localStorage.getItem('org_branch_id') == '0' ? <h3 style={{ color: 'red' }}>Branch Selection will change the Booking, Invoicing and Reporting Branch</h3> : null}
        </div>
        <GridContainer style={{ padding: '2em' }}>
          <GridItem xs={6} sm={6} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                format="dd/MM/yyyy"
                value={from}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setFrom(date)}
              />
            </MuiPickersUtilsProvider>
          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }

          <GridItem xs={6} sm={6} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="To"
                format="dd/MM/yyyy"
                value={to}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setTo(date)}
              />
            </MuiPickersUtilsProvider>

          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }
          <GridItem xs={6} sm={6} md={2} lg={2}>
            <FormControl variant="filled" className={classes.formControl} style={{ width: "-webkit-fill-available" }} >
              <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={state.status}
                onChange={handleChange}
                inputProps={{
                  name: 'status',
                  id: 'filled-age-native-simple',
                }}
              >
                <MenuItem aria-label="Select" value="All" >All</MenuItem>
                <MenuItem value={'Just Booked'}>Just Booked</MenuItem>
                <MenuItem value={'Only Phlebotomy Done'}>Only Phlebotomy Done</MenuItem>
                <MenuItem value={'Lying Pending Results'}>Lying Pending Results</MenuItem>
                <MenuItem value={'Results Done But not Signed'}>Results Done But not Signed</MenuItem>
                <MenuItem value={'Results Ready'}>Results Ready</MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          {
            localStorage.getItem('org_branch_id') == '0' ?
              <GridItem xs={12} sm={12} md={2} lg={2}  >
                <FormControl variant="filled" className={classes.formControl} style={{ width: "10em" }} >
                  <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={branch}
                    onChange={(e) => {
                      var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                      if (result) {
                        setBranch(e.target.value)
                        localStorage.setItem('branch_id', e.target.value)
                        localStorage.setItem('branch_name', selectBranch[e.target.value])
                        window.location.reload()
                      }

                    }}
                  >
                    <MenuItem value={"0"} disabled>Select</MenuItem>
                    {
                      branches.map((item) => {
                        return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

                      })
                    }
                  </Select>
                </FormControl>
              </GridItem>
              : null
          }
          <GridItem xs={12} sm={12} md={2} lg={2} style={{ margin: 'auto' }} >

            <Button variant="contained" color="primary" disableElevation disabled={loading}
              onClick={() => {
                setPage(0)
                document.getElementById('search_data').value = ""
                var role = localStorage.getItem('role')
                var patient_id = localStorage.getItem('patient_id')
                setLoading(true)

                var branchID = localStorage.getItem('branch_id')
                var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
                var vSearchStr = JSON.stringify(SearchFormData);

                var url = "https://reports.mpl-labs.pk:8443/4DACTION/InvoiceListCalledByPrintPortal"


                to.setHours(15)
                from.setHours(15)
                
                fetch(url,{
                  method:"POST",
                  body:JSON.stringify({
                    branch_id:JSON.parse(branchID),
                    date_start:from.toISOString(),
                    date_end:to.toISOString()
                  })
                }).then((res) => res.json())
                  .then((result) => {
                    var the_rows = []
                    result.map((data) => {
                      var br_title = ""
            
                      if (data.branch_name == "METROPOLE") {
                        br_title = "Islamabad"
                      } else {
            
                        br_title = data.branch_name.split('MPL,')[1]
            
            
                      }
                      var obj = {
                        ...data,
                        tests_included:data.tests_included.includes('^') ? data.tests_included.replaceAll('^','(').replaceAll('|',"),")+")" : data.tests_included.replaceAll('^','(').replaceAll('|',"),") ,
                        patient_invoice:  <center>
                        <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
                          var temp = {
                            Invoice_No:data.invoice_no+""
                          }
                          fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                            method: 'POST',
                            'Content-Type': "application/json",
                            body: JSON.stringify(temp)
                          }).then((res) => res.json()).then(async (record) => {
                            svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                              svgString2Image(record[0].FBR_QR_Code, 800, 600, 'png', async (FBR_QR_Code) => {
                              var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                              var print_date = new Date().ddmmyyy()
                              var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                              var orders = JSON.parse(record[0].Array_Test_Code)
          
                              var counter = -1
            
                              var groupBox={}
            
                              var TableRows=[]
            
                              var grouping=JSON.parse(record[0].ArrGroupingTitle)

                              var fee=0
                              var disc=0
                              var net_value=0
            
                              grouping.map((title,index)=>{
                                if(title.trim()==""){
                                  return
                                 }
                                 fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                                 disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                                 net_value=JSON.parse(record[0].Array_Payable)[index] + net_value
            
                                groupBox[title]={
                                  sn: index+1,
                                  test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
                                  reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                                  fee: numberWithCommas((fee).toFixed(2)),
                                  disc: numberWithCommas((disc).toFixed(2)),
                                  net_value: numberWithCommas((net_value).toFixed(2)),
                                  branch: record[0].Branch_Name,
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  name: record[0].Patient_Name,
                                  passport: record[0].Passport_No,
                      
                                }
                              })
            
                              Object.keys(groupBox).map((key)=>{
                                TableRows.push(groupBox[key])
            
                              })
                              console.log(groupBox)
            
                              var up_order = await orders.map((item,index) => {
                                
                                if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
                                
                                  return
                                 }

                                fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                                disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                                net_value=JSON.parse(record[0].Array_Payable)[index] + net_value

                                counter++
                                TableRows.push({
                                  sn: counter + 1,
                                  test_title: JSON.parse(record[0].Array_Test_Name)[index],
                                  reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                                  fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
                                  disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                                  net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                                  branch: record[0].Branch_Name,
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  name: record[0].Patient_Name,
                                  passport: record[0].Passport_No,
                                                   //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                                
                                })
                                return ""
            
                              })
          
                              Promise.all(up_order).then((values) => {
                                var obj1 = {
                                  branch: record[0].Branch_Name,
                                  barcode: barcode,
                                  name: record[0].Patient_Name,
                                  age_gender: record[0].Age_Gender,
                                  password: record[0].Password,
                                  patient_id: record[0].Login_Name,
                                  Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                                   Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                                  print_time: formatAMPM(new Date()),
                                  print_date: print_date,
                                  Ref_By: record[0].Ref_By,
                                  Ref_No: record[0].Ref_No,
          
                                  BranchAddress:record[0].BranchAddress,
                                  BranchPhone:record[0].BranchPhone,
                                  BranchTiming:record[0].BranchTiming,
                                  BranchTitle:record[0].BranchTitle,
                                  BranchExcLetterhead:record[0].BranchExcLetterhead,
                                  // FBR
                                  pos:FBR_QR_Code,
                                  pos_id:record[0].FBR_ID,

                                  Invoice_Remarks: record[0].Invoice_Remarks,
                                  Patient_Comments: record[0].Patient_Comments,
                                  invoice_time: msToTime(record[0].Invoice_Time),
                                  Entitlement_Name: record[0].Entitlement_Name,
                                  invoice_date: invoice_date,
                                  Discount_Total: numberWithCommas((disc).toFixed(2)),
                                  Invoice_Value: numberWithCommas((fee).toFixed(2)),
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  Ticket_No: record[0].Ticket_No,
                                  invoice_id: record[0].Invoice_ID,
                                  contact: record[0].Mobile_No,
                                  Panel_Name: record[0].Panel_Name,
                                  passport_no: record[0].Passport_No,
                                  flight_date: flight_date,
                                  flight_no: record[0].Flight_No,
                                  flight_time: msToTime(record[0].Flight_Time),
                                  toAddress:record[0].To_Address,
                                  reports: TableRows,
                                  Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
                                  Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
                                  Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
                                  // cnic: "37555555555",

                                          // for new MR panel 3-JAN-2023
                      if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
                      //  if_panelMR_cnic_father:true,
       // cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
       father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null"
      //  father_husband:"Ashraf Mehmood"
   

          
                                }
                                setSelected(obj1)
          
                                setTimeout(() => {
                                  setPatientInvoice(true)
          
                                }, 500);
                              })
          
                            })
          
                          })
                          })
          
                        }}>
                          <  ReceiptIcon />
                        </Button>
                      </center>
                        ,
                        simple_invoice: <center>
                        <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
                          var temp = {
                            Invoice_No:data.invoice_no+""
                          }
                          fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                            method: 'POST',
                            'Content-Type': "application/json",
                            body: JSON.stringify(temp)
                          }).then((res) => res.json()).then(async (record) => {
                            svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                              svgString2Image(record[0].FBR_QR_Code, 800, 600, 'png', async (FBR_QR_Code) => {
                              var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                              var print_date = new Date().ddmmyyy()
                              var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                              var orders = JSON.parse(record[0].Array_Test_Code)
            
                              var counter = -1
            
                              var groupBox={}
            
                              var TableRows=[]
            
                              var grouping=JSON.parse(record[0].ArrGroupingTitle)
            
                              var fee=0
                              var disc=0
                              var net_value=0
            
                              grouping.map((title,index)=>{
                                if(title.trim()==""){
                                  return
                                 }
                                 fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                                 disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                                 net_value=JSON.parse(record[0].Array_Payable)[index] + net_value
            
                                groupBox[title]={
                                  sn: index+1,
                                  test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
                                  reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                                  fee: numberWithCommas((fee).toFixed(2)),
                                  disc: numberWithCommas((disc).toFixed(2)),
                                  net_value: numberWithCommas((net_value).toFixed(2)),
                                  branch: record[0].Branch_Name,
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  name: record[0].Patient_Name,
                                  passport: record[0].Passport_No
                                }
                              })
            
                              Object.keys(groupBox).map((key)=>{
                                TableRows.push(groupBox[key])
            
                              })
                              console.log(groupBox)
            
                              var up_order = await orders.map((item,index) => {
                                
                                if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
                                
                                  return
                                 }
            
                                fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                                disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                                net_value=JSON.parse(record[0].Array_Payable)[index] + net_value
            
                                counter++
                                TableRows.push({
                                  sn: counter + 1,
                                  test_title: JSON.parse(record[0].Array_Test_Name)[index],
                                  reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                                  fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
                                  disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                                  net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                                  branch: record[0].Branch_Name,
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  name: record[0].Patient_Name,
                                  passport: record[0].Passport_No,
                                                      //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                                })
                                return ""
            
                              })
            
                              Promise.all(up_order).then((values) => {
                                var obj1 = {
                                  branch: record[0].Branch_Name,
                                  barcode: barcode,
                                  name: record[0].Patient_Name,
                                  age_gender: record[0].Age_Gender,
                                  password: record[0].Password,
                                  patient_id: record[0].Login_Name,
                                  Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                                   Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                                  print_time: formatAMPM(new Date()),
                                  print_date: print_date,
                                  Ref_By: record[0].Ref_By,
                                  Ref_No: record[0].Ref_No,
                                  pos:FBR_QR_Code,
                                  pos_id:record[0].FBR_ID,
                                  BranchAddress:record[0].BranchAddress,
                                  BranchPhone:record[0].BranchPhone,
                                  BranchTiming:record[0].BranchTiming,
                                  BranchTitle:record[0].BranchTitle,
                                  BranchExcLetterhead:record[0].BranchExcLetterhead,
            
            
                                  Invoice_Remarks: record[0].Invoice_Remarks,
                                  Patient_Comments: record[0].Patient_Comments,
                                  invoice_time: msToTime(record[0].Invoice_Time),
                                  Entitlement_Name: record[0].Entitlement_Name,
                                  invoice_date: invoice_date,
                                  Discount_Total: numberWithCommas((disc).toFixed(2)),
                                  Invoice_Value: numberWithCommas((fee).toFixed(2)),
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  Ticket_No: record[0].Ticket_No,
                                  invoice_id: record[0].Invoice_ID,
                                  contact: record[0].Mobile_No,
                                  Panel_Name: record[0].Panel_Name,
                                  passport_no: record[0].Passport_No,
                                  flight_date: flight_date,
                                  flight_no: record[0].Flight_No,
                                  flight_time: msToTime(record[0].Flight_Time),
                                  toAddress:record[0].To_Address,
                                  reports: TableRows,
                                  Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
                                  Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
                                  Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
                                      // cnic: "37505-1287363-9",

                      // for new MR panel 3-JAN-2023
                      if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
                      //  if_panelMR_cnic_father:true,
       // cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
      //  father_husband:record[0].if_panelMR_cnic_father?record[0].father_husband:"null"
     father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null"

      //  father_husband:"Ashraf Mehmood"
            
                                }
                                setSelected(obj1)
            
                                setTimeout(() => {
                                  setSimpleInvoice(true)
            
                                }, 500);
                              })
            
                            })
            
                            })
                          })
            
                        }}>
                          <  ReceiptIcon />
                        </Button>
                      </center>
                        ,
                        sample_invoice: <center>
                        <Button variant="contained" style={{ backgroundColor: 'blue', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
                          var temp = {
                            Invoice_No:data.invoice_no+""
                          }
                          fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                            method: 'POST',
                            'Content-Type': "application/json",
                            body: JSON.stringify(temp)
                          }).then((res) => res.json()).then(async (record) => {
                            svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                              var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                              var print_date = new Date().ddmmyyy()
                              var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                              var orders = JSON.parse(record[0].Array_Test_Code)
            
                              var counter = -1
            
                              var groupBox={}
            
                              var TableRows=[]
            
                              var grouping=JSON.parse(record[0].ArrGroupingTitle)
            
                              var fee=0
                              var disc=0
                              var net_value=0
            
                              grouping.map((title,index)=>{
                                if(title.trim()==""){
                                  return
                                 }
                                 fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                                 disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                                 net_value=JSON.parse(record[0].Array_Payable)[index] + net_value
            
                                groupBox[title]={
                                  sn: index+1,
                                  test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
                                  reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                                  fee: numberWithCommas((fee).toFixed(2)),
                                  disc: numberWithCommas((disc).toFixed(2)),
                                  net_value: numberWithCommas((net_value).toFixed(2)),
                                  branch: record[0].Branch_Name,
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  name: record[0].Patient_Name,
                                  passport: record[0].Passport_No
                                }
                              })
            
                              Object.keys(groupBox).map((key)=>{
                                TableRows.push(groupBox[key])
            
                              })
                              console.log(groupBox)
            
                              var up_order = await orders.map((item,index) => {
                                
                                if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
                                
                                  return
                                 }
            
                                fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                                disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                                net_value=JSON.parse(record[0].Array_Payable)[index] + net_value
            
                                counter++
                                TableRows.push({
                                  sn: counter + 1,
                                  test_title: JSON.parse(record[0].Array_Test_Name)[index],
                                  reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                                  fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
                                  disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                                  net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                                  branch: record[0].Branch_Name,
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  name: record[0].Patient_Name,
                                  passport: record[0].Passport_No,
                                   //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                                
                                })
                                return ""
            
                              })
            
                              Promise.all(up_order).then((values) => {
                                var obj1 = {
                                  branch: record[0].Branch_Name,
                                  barcode: barcode,
                                  name: record[0].Patient_Name,
                                  age_gender: record[0].Age_Gender,
                                  password: record[0].Password,
                                  patient_id: record[0].Login_Name,
                                  Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                                   Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                                  print_time: formatAMPM(new Date()),
                                  print_date: print_date,
                                  Ref_By: record[0].Ref_By,
                                  Ref_No: record[0].Ref_No,
            
                                  BranchAddress:record[0].BranchAddress,
                                  BranchPhone:record[0].BranchPhone,
                                  BranchTiming:record[0].BranchTiming,
                                  BranchTitle:record[0].BranchTitle,
                                  BranchExcLetterhead:record[0].BranchExcLetterhead,
            
            
                                  Invoice_Remarks: record[0].Invoice_Remarks,
                                  Patient_Comments: record[0].Patient_Comments,
                                  invoice_time: msToTime(record[0].Invoice_Time),
                                  Entitlement_Name: record[0].Entitlement_Name,
                                  invoice_date: invoice_date,
                                  Discount_Total: numberWithCommas((disc).toFixed(2)),
                                  Invoice_Value: numberWithCommas((fee).toFixed(2)),
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  Ticket_No: record[0].Ticket_No,
                                  invoice_id: record[0].Invoice_ID,
                                  contact: record[0].Mobile_No,
                                  Panel_Name: record[0].Panel_Name,
                                  passport_no: record[0].Passport_No,
                                  flight_date: flight_date,
                                  flight_no: record[0].Flight_No,
                                  flight_time: msToTime(record[0].Flight_Time),
                                  toAddress:record[0].To_Address,
                                  reports: TableRows,
                                  Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
                                  Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
                                  Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
                                                  // cnic: "37505-1287363-9",

                      // for new MR panel 3-JAN-2023
                      if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
                      //  if_panelMR_cnic_father:true,
      //  cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
      //  father_husband:record[0].if_panelMR_cnic_father?record[0].father_husband:"null"
    father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null"

      //  father_husband:"Ashraf Mehmood"
            
                                }
                                setSelected(obj1)
            
                                setTimeout(() => {
                                  setSampleInvoice(true)
            
                                }, 500);
                              })
            
                            })
            
            
                          })
            
                        }}>
                          <  ReceiptIcon />
                        </Button>
                      </center>,
                       sample_invoiceNew: <center>
                       <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
                         var temp = {
                           Invoice_No:data.invoice_no+""
                         }
                         fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                           method: 'POST',
                           'Content-Type': "application/json",
                           body: JSON.stringify(temp)
                         }).then((res) => res.json()).then(async (record) => {
                           svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                             var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                             var print_date = new Date().ddmmyyy()
                             var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                             var orders = JSON.parse(record[0].Array_Test_Code)
           
                             var counter = -1
           
                             var groupBox={}
           
                             var TableRows=[]
           
                             var grouping=JSON.parse(record[0].ArrGroupingTitle)
           
                             var fee=0
                             var disc=0
                             var net_value=0
           
                             grouping.map((title,index)=>{
                               if(title.trim()==""){
                                 return
                                }
                                fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                                disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                                net_value=JSON.parse(record[0].Array_Payable)[index] + net_value
           
                               groupBox[title]={
                                 sn: index+1,
                                 test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
                                 reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                                 fee: numberWithCommas((fee).toFixed(2)),
                                 disc: numberWithCommas((disc).toFixed(2)),
                                 net_value: numberWithCommas((net_value).toFixed(2)),
                                 branch: record[0].Branch_Name,
                                 User_Name: record[0].user_name,
                                 cnic: record[0].CNIC,
                                 name: record[0].Patient_Name,
                                 passport: record[0].Passport_No
                               }
                             })
           
                             Object.keys(groupBox).map((key)=>{
                               TableRows.push(groupBox[key])
           
                             })
                             console.log(groupBox)
           
                             var up_order = await orders.map((item,index) => {
                               
                               if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
                               
                                 return
                                }
           
                               fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                               disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                               net_value=JSON.parse(record[0].Array_Payable)[index] + net_value
           
                               counter++
                               TableRows.push({
                                 sn: counter + 1,
                                 test_title: JSON.parse(record[0].Array_Test_Name)[index],
                                 reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                                 fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
                                 disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                                 net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                                 branch: record[0].Branch_Name,
                                 User_Name: record[0].user_name,
                                 cnic: record[0].CNIC,
                                 name: record[0].Patient_Name,
                                 passport: record[0].Passport_No,
                                  //18-jan-2023 show tret detail in row parameter tests_line_details
                     // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                     tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                               
                               })
                               return ""
           
                             })
           
                             Promise.all(up_order).then((values) => {
                               var obj1 = {
                                 branch: record[0].Branch_Name,
                                 barcode: barcode,
                                 name: record[0].Patient_Name,
                                 age_gender: record[0].Age_Gender,
                                 password: record[0].Password,
                                 patient_id: record[0].Login_Name,
                                 Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                                  Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                                 print_time: formatAMPM(new Date()),
                                 print_date: print_date,
                                 Ref_By: record[0].Ref_By,
                                 Ref_No: record[0].Ref_No,
           
                                 BranchAddress:record[0].BranchAddress,
                                 BranchPhone:record[0].BranchPhone,
                                 BranchTiming:record[0].BranchTiming,
                                 BranchTitle:record[0].BranchTitle,
                                 BranchExcLetterhead:record[0].BranchExcLetterhead,
           
           
                                 Invoice_Remarks: record[0].Invoice_Remarks,
                                 Patient_Comments: record[0].Patient_Comments,
                                 invoice_time: msToTime(record[0].Invoice_Time),
                                 Entitlement_Name: record[0].Entitlement_Name,
                                 invoice_date: invoice_date,
                                 Discount_Total: numberWithCommas((disc).toFixed(2)),
                                 Invoice_Value: numberWithCommas((fee).toFixed(2)),
                                 User_Name: record[0].user_name,
                                 cnic: record[0].CNIC,
                                 Ticket_No: record[0].Ticket_No,
                                 invoice_id: record[0].Invoice_ID,
                                 contact: record[0].Mobile_No,
                                 Panel_Name: record[0].Panel_Name,
                                 passport_no: record[0].Passport_No,
                                 flight_date: flight_date,
                                 flight_no: record[0].Flight_No,
                                 flight_time: msToTime(record[0].Flight_Time),
                                 toAddress:record[0].To_Address,
                                 reports: TableRows,
                                 Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
                                 Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
                                 Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
                                                 // cnic: "37505-1287363-9",

                     // for new MR panel 3-JAN-2023
                     if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
                     //  if_panelMR_cnic_father:true,
     //  cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
     //  father_husband:record[0].if_panelMR_cnic_father?record[0].father_husband:"null"
   father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null"

     //  father_husband:"Ashraf Mehmood"
           
                               }
                               setSelected(obj1)
           
                               setTimeout(() => {
                                 setSampleInvoiceNew(true)
           
                               }, 500);
                             })
           
                           })
           
           
                         })
           
                       }}>
                         <  ReceiptIcon />
                       </Button>
                     </center>,
                        receipt:  <center>
                        <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
                          var temp = {
                            Invoice_No:data.invoice_no+""
                          }
                          fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                            method: 'POST',
                            'Content-Type': "application/json",
                            body: JSON.stringify(temp)
                          }).then((res) => res.json()).then(async (record) => {
            
                            svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                              var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                              var print_date = new Date().ddmmyyy()
                              var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                              var orders = JSON.parse(record[0].Array_Test_Code)
            
                              var counter = -1
            
                              var groupBox={}
            
                              var TableRows=[]
            
                              var grouping=JSON.parse(record[0].ArrGroupingTitle)
            
                              var fee=0
                              var disc=0
                              var net_value=0
            
                              grouping.map((title,index)=>{
                                if(title.trim()==""){
                                  return
                                 }
                                 fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                                 disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                                 net_value=JSON.parse(record[0].Array_Payable)[index] + net_value
            
                                groupBox[title]={
                                  sn: index+1,
                                  test_title: ( groupBox[title] ==undefined ? title + "\n" : groupBox[title].test_title ) + "\n - "+ JSON.parse(record[0].Array_Test_Name)[index]  ,
                                  reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                                  fee: numberWithCommas((fee).toFixed(2)),
                                  disc: numberWithCommas((disc).toFixed(2)),
                                  net_value: numberWithCommas((net_value).toFixed(2)),
                                  branch: record[0].Branch_Name,
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  name: record[0].Patient_Name,
                                  passport: record[0].Passport_No
                                }
                              })
            
                              Object.keys(groupBox).map((key)=>{
                                TableRows.push(groupBox[key])
            
                              })
                              console.log(groupBox)
            
                              var up_order = await orders.map((item,index) => {
                                
                                if(JSON.parse(record[0].ArrGroupingTitle)[index].trim()!=""){
                                
                                  return
                                 }
            
                                fee=JSON.parse(record[0].ArrPriceActual )[index] + fee
                                disc=((JSON.parse(record[0].ArrPriceActual)[index]) - (JSON.parse(record[0].Array_Payable)[index])) + disc
                                net_value=JSON.parse(record[0].Array_Payable)[index] + net_value
            
                                counter++
                                TableRows.push({
                                  sn: counter + 1,
                                  test_title: JSON.parse(record[0].Array_Test_Name)[index],
                                  reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[index],
                                  fee: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index]).toFixed(2)),
                                  disc: numberWithCommas((JSON.parse(record[0].ArrPriceActual)[index] - JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                                  net_value: numberWithCommas((JSON.parse(record[0].Array_Payable)[index]).toFixed(2)),
                                  branch: record[0].Branch_Name,
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  name: record[0].Patient_Name,
                                  passport: record[0].Passport_No
                                
                                })
                                return ""
            
                              })
            
                              Promise.all(up_order).then((values) => {
                                var obj1 = {
                                  branch: record[0].Branch_Name,
                                  barcode: barcode,
                                  name: record[0].Patient_Name,
                                  age_gender: record[0].Age_Gender,
                                  password: record[0].Password,
                                  patient_id: record[0].Login_Name,
                                  Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                                   Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                                  print_time: formatAMPM(new Date()),
                                  print_date: print_date,
                                  Ref_By: record[0].Ref_By,
                                  Ref_No: record[0].Ref_No,
            
                                  BranchAddress:record[0].BranchAddress,
                                  BranchPhone:record[0].BranchPhone,
                                  BranchTiming:record[0].BranchTiming,
                                  BranchTitle:record[0].BranchTitle,
                                  BranchExcLetterhead:record[0].BranchExcLetterhead,
            
            
                                  Invoice_Remarks: record[0].Invoice_Remarks,
                                  Patient_Comments: record[0].Patient_Comments,
                                  invoice_time: msToTime(record[0].Invoice_Time),
                                  Entitlement_Name: record[0].Entitlement_Name,
                                  invoice_date: invoice_date,
                                  Discount_Total: numberWithCommas((disc).toFixed(2)),
                                  Invoice_Value: numberWithCommas((fee).toFixed(2)),
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  Ticket_No: record[0].Ticket_No,
                                  invoice_id: record[0].Invoice_ID,
                                  contact: record[0].Mobile_No,
                                  Panel_Name: record[0].Panel_Name,
                                  passport_no: record[0].Passport_No,
                                  flight_date: flight_date,
                                  flight_no: record[0].Flight_No,
                                  flight_time: msToTime(record[0].Flight_Time),
                                  toAddress:record[0].To_Address,
                                  reports: TableRows,
                                  Invoice_Payable: numberWithCommas(record[0].Invoice_Payable.toFixed(2)),
                                  Invoice_Received: numberWithCommas(record[0].Invoice_Received.toFixed(2)),
                                  Invoice_Balance: numberWithCommas(record[0].Invoice_Balance.toFixed(2)),
            
                                }
                                setSelectedReceipt(obj1)
            
                                setTimeout(() => {
                                  setSelectReceiptOpen(true)
                        
                                }, 500);
                              })
            
                            })
            
            
                          })
            
                        }}>
                          <  ReceiptIcon />
                        </Button>
                      </center>
                      ,
                        barcode: <center>
                        <Button variant="contained" style={{ backgroundColor: 'white', color: 'black' }} key={data.invoice_no} disableElevation onClick={() => {
                         
                            var obj1 = {
                              sample_id: data.invoice_no,
                              age_gender: "",
                              test_title: "Nasal Swab/Nasal Secretions",
                              date: new Date().ddmmyyy(),
                              mpl_id: data.invoice_no,
                              name: data.patient_name,
                              patient_id: data.patient_id,
                              time: formatAMPM(new Date()),
            
            
                            }
                            var url = "http://localhost:1000/GETBarcode"
                            fetch(url, {
                              method: "POST",
                              body: JSON.stringify(obj1),
                              headers: {
                                'Content-Type': "application/json"
                              }
                            }).then(() => {
                              alert("Print Request Sent")
                            })
                          
                        }} >
                          Barcode
            </Button>
                      </center>
                      ,
                        lab_order: <center>
                        <Button variant="contained" style={{ backgroundColor: 'purple', color: 'white' }} key={data.invoice_no} disableElevation onClick={() => {
                          var temp = {
                            Invoice_No:data.invoice_no+""
                          }
                          fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
                            method: 'POST',
                            'Content-Type': "application/json",
                            body: JSON.stringify(temp)
                          }).then((res) => res.json()).then(async (record) => {
                            svgString2Image(record[0].BarCode, 800, 600, 'png', async (barcode) => {
                              var flight_date = new Date(record[0].Flight_Date).ddmmyyy()
                              var print_date = new Date().ddmmyyy()
                              var invoice_date = new Date(record[0].Invoice_Date).ddmmyyy()
                              var orders = JSON.parse(record[0].Array_Test_Code)
            
                              var counter = -1
                              var up_order = await orders.map((item) => {
                                counter++
                                return {
                                  sn: counter + 1,
                                  //add group title seprate 1801348 9-jan-2023 by sir najam updation
                                  test_title: JSON.parse(record[0].Array_Test_Name)[counter] ,
                                  group_title: JSON.parse(record[0].ArrGroupingTitle)[counter]==""? "" :JSON.parse(record[0].ArrGroupingTitle)[counter] ,
                                  // test_title: JSON.parse(record[0].Array_Test_Name)[counter],
                                  reporting_date: JSON.parse(record[0].Array_DeliveryDateTime)[counter],
                                  fee: (JSON.parse(record[0].Array_Price)[counter]).toFixed(2),
                                  disc: JSON.parse(record[0].Array_Discount)[counter].toFixed(2),
                                  net_value: JSON.parse(record[0].Array_Payable)[counter].toFixed(2),
                                  branch: record[0].Branch_Name,
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  name: record[0].Patient_Name,
                                  passport: record[0].Passport_No,
                                    //18-jan-2023 show tret detail in row parameter tests_line_details
                      // tests_line_details:"("+ JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")+")"
                      tests_line_details: JSON.parse(record[0].tests_line_details)[counter].replaceAll("|",",")
                                
                                }
            
            
                              })
            
                              Promise.all(up_order).then((values) => {
                                var obj1 = {
                                  branch: record[0].Branch_Name,
                                  barcode: barcode,
                                  name: record[0].Patient_Name,
                                  age_gender: record[0].Age_Gender,
                                  Base64_ImagePassport:"data:image/png;base64,"+record[0].Base64_ImagePassport,
                                  Base64_PicturePatient:"data:image/png;base64,"+record[0].Base64_PicturePatient,
                                  password: record[0].Password,
                                  patient_id: record[0].Login_Name,
                                  print_time: formatAMPM(new Date()),
                                  print_date: print_date,
                                  Ref_By: record[0].Ref_By,
                                  Ref_No: record[0].Ref_No,
                                  Invoice_Remarks: record[0].Invoice_Remarks,
                                  Patient_Comments: record[0].Patient_Comments,
                                  invoice_time: msToTime(record[0].Invoice_Time),
                                  Entitlement_Name: record[0].Entitlement_Name,
                                  invoice_date: invoice_date,
                                  Discount_Total: record[0].Discount_Total.toFixed(2),
                                  Invoice_Value: record[0].Invoice_Value.toFixed(2),
                                  User_Name: record[0].user_name,
                                  cnic: record[0].CNIC,
                                  Ticket_No: record[0].Ticket_No,
                                  invoice_id: record[0].Invoice_ID,
                                  contact: record[0].Mobile_No,
                                  Panel_Name: record[0].Panel_Name,
                                  Destination:record[0].Destination,
                                  Airline:record[0].Airline,
                                  passport_no: record[0].Passport_No,
                                  flight_date: flight_date,
                                  flight_no: record[0].Flight_No,
                                  flight_time: msToTime(record[0].Flight_Time),
                                  toAddress:record[0].To_Address,
                                  reports: values,
                                  Invoice_Payable: record[0].Invoice_Payable.toFixed(2),
                                  Invoice_Received: record[0].Invoice_Received.toFixed(2),
                                  Invoice_Balance: record[0].Invoice_Balance.toFixed(2),
                                            // cnic: "37505-1287363-9",

                      // for new MR panel 3-JAN-2023
                      if_panelMR_cnic_father:record[0].if_panelMR_cnic_father,
                      //  if_panelMR_cnic_father:true,
      //  cnic:"37405-1287363-9", Already oper aa rha es lye es cond ko bhej dya hai
      //  father_husband:record[0].if_panelMR_cnic_father?record[0].father_husband:"null"
    father_husband:record[0].if_panelMR_cnic_father==true && record[0].father_husband!=""?record[0].father_husband:"null"

      //  father_husband:"Ashraf Mehmood"
      // this is for row adding by sir najam waiting for sir kamran 10-jan-2023 and change in InvoiveItemTableRow 69
    // parameterData:["Bilirubin Total,ALT (SGPT),AST (SGOT),Alkaline Phosphatase (ALP),Gamma GT"]
            
                                }
                                setSelectedOrders(obj1)
                                setTimeout(() => {
                                  setLabOrder(true)
            
                                }, 1000);
                              })
            
                            })
            
            
                          })
            
                        }}>
                          <  ReceiptIcon />
                        </Button>
                      </center>,
                       //09-march-2023
  barcodeSticker:<center>
  <Button variant="contained" style={{backgroundColor:'white', color:'black'}} key={data.Invoice_ID} disableElevation onClick={()=>{
    var temp = {
      Invoice_No:data.invoice_no+""
    }
    fetch('https://reports.mpl-labs.pk:8443/4DACTION/Web_InvoiceAndLabOrderPrinting', {
      method: 'POST',
      'Content-Type': "application/json",
      body: JSON.stringify(temp)
    }).then((res) => res.json()).then(async (record) => {
      // console.log("/Web_InvoiceAndLabOrderPrinting record"+JSON.stringify(record))
        var obj1={
          name:data.patient_name,
          age_gender:record[0].Age_Gender,
          mpl_id:data.invoice_no,
          ref_by:record[0].Ref_By,
          date:new Date().ddmmyyy(),
          study:data.tests_included

        }
        console.log(obj1)
        var url="http://localhost:1000/ReportSticker"
        fetch(url,{
          method:"POST",
          body:JSON.stringify(obj1),
          headers:{
            'Content-Type':"application/json"
          }
        }).then(()=>{
          alert("Print Request Sent")
        })
      })
    
    }}
    
    >
Report Sticker
    </Button>
    </center>,
                      }
                      the_rows.push(obj)
            
                    })

                    setRows(the_rows)
                    setOrg_Rows(the_rows)
                    setLoading(false)
                    setPage(0)
                  }).catch(()=>{
                    setLoading(false)
                    setPage(0)
                  })

              }}
            >
              Search<SearchIcon />
            </Button>

          </GridItem>
        </GridContainer>
        <div align="right">

          <TextField id="standard-basic" id="search_data" label="Search by Invoice ID, Passport No , Reference No , CNIC , Patient Name , Mobile No , Test Name and Test Code"
            style={{ maxWidth: '50em' }}
            fullWidth={true}

            onChange={async (e) => {
              var lab_tests = []
              var text = e.target.value
              var data = await org_rows.map((item) => {
            
                if (item.mobile_no.includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.patient_name.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.patient_id.trim().toLowerCase().includes(text.trim().toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.invoice_no.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.remarks.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.tests_included.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                return item

              })
              Promise.all(data).then((item) => {

                setRows(lab_tests)

              })

            }}
          />

        </div>
        {loading && localStorage.getItem('branch_id') != '0' ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                        {columns.map((column) => {
                          const value = row[column.id];
                          var status_color = value == 'Just Booked' ? 'brown' : value == 'Only Phlebotomy Done' ? 'red' :
                            value == 'Lying Pending Results' ? 'purple' : value == 'Results Done But not Signed' ? 'blue' : value == 'Results Ready' ? 'green' : ''

                          return (
                            <>
                              {status_color.trim() != '' ?
                                <TableCell key={column.id} align={column.align} style={{ backgroundColor: status_color, color: 'white' }} >
                                  <i><b>{value}</b></i>
                                </TableCell>
                                : <TableCell key={column.id} align={column.align} >
                                  {value}
                                </TableCell>}
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        }


      </Paper>
      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={simple_invoice}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setSimpleInvoice(false)
          setSelected({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Simple Invoice</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selected.name != undefined ?
              <center> <Fragment key={"sqq"}>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Simple_Invoice invoice={selected} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setSimpleInvoice(false)
              setSelected({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={sample_invoice}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setSampleInvoice(false)
          setSelected({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Service Order</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selected.name != undefined ?
              <center> <Fragment key={"sqq"}>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Sample_Invoice invoice={selected} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setSampleInvoice(false)
              setSelected({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      {/* 1st-april-2023 Outsource Service order to hide contact no and ref by Gp me update kiya tha thats why yahan b  */}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={sample_invoiceNew}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setSampleInvoiceNew(false)
          setSelected({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Outsource Service Order</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selected.name != undefined ?
              <center> <Fragment key={"sqq"}>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Sample_InvoiceNew invoice={selected} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setSampleInvoiceNew(false)
              setSelected({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={patient_invoice}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setPatientInvoice(false)
          setSelected({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Printed Invoice</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selected.name != undefined ?
              <center> <Fragment key={"sqq"}>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Invoice invoice={selected} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setPatientInvoice(false)
              setSelected({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={laborder}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setLabOrder(false)
          setSelectedOrders({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Order</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectOrders.name != undefined ?
              <center> <Fragment key={"sqq"} id>
                <PDFViewer width="1000" height="1000" className="app" >
                  <LabOrder invoice={selectOrders} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setLabOrder(false)
              setSelectedOrders({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      {/* ////////////////////// Receipt and data pass to this modal selectedReceipt (that are upadte in receipt 297 useEffect and search wala b same hoga)/////////////////////////// */}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={selectReceiptOpen}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setSelectReceiptOpen(false)
          setSelectedReceipt({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Receipt</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectedReceipt.name != undefined ?
              <center> <Fragment key={"sqq"} id>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Receipt invoice={selectedReceipt} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setSelectReceiptOpen(false)
              setSelectedReceipt({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      {/* ////////////////////// Receipt /////////////////////////// */}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={OpenCovidTemplate}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setCovidTemplate(false)
          setSelectedCovid({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Report</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectedCovid.name != undefined ?
              <center> <Fragment key={"sqwewq"} >
                <GridContainer>
                  <GridItem md={2}>

                    <PDFDownloadLink
                      style={{
                        float: 'left'
                      }}
                      document={<CovidTemplate invoice={selectedCovid} />} fileName={selectedCovid.invoice_id + ".pdf"}>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                        <Button variant="contained" color="primary">Download Report</Button>)}
                    </PDFDownloadLink>
                    <br />
                    <br />
                    <Button
                      style={{
                        float: 'left'
                      }}
                      variant="contained" color={'secondary'} onClick={() => {
                        setCovidTemplate(false)
                        var temp = selectedCovid
                        localStorage.setItem('covid_report', JSON.stringify(temp))
                        setSelectedCovid({
                          reports: []
                        })
                        //console.log(temp)
                        setTimeout((temp) => {
                          console.log(temp)
                          setSelectedCovid(JSON.parse(localStorage.getItem('covid_report')))
                          setTimeout(() => {
                            setCovidTemplate(true)
                          }, 500);

                        }, 400);
                      }}>
                      Refresh Report
       <RefreshIcon /></Button>

                  </GridItem>

                  <GridItem md={10}>
                    <PDFViewer width="1000" height="1000" className="app" >
                      <CovidTemplate invoice={selectedCovid} />
                    </PDFViewer>
                  </GridItem>
                </GridContainer>

              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setCovidTemplate(false)
              setSelectedCovid({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={OpenOfficialCovidTemplate}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setOfficialCovidTemplate(false)
          setSelectedOfficialCovidReport({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Report</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectedOfficialCovidReport.name != undefined ?
              <center> <Fragment key={"sqwewq"} >
                <GridContainer>
                  <GridItem md={2}>

                    <PDFDownloadLink
                      style={{
                        float: 'left'
                      }}
                      document={<OfficialCovidTemplate invoice={selectedOfficialCovidReport} />} fileName={selectedOfficialCovidReport.invoice_id + ".pdf"}>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                        <Button variant="contained" color="primary">Download Report</Button>)}
                    </PDFDownloadLink>
                    <br />
                    <br />
                    <Button
                      style={{
                        float: 'left'
                      }}
                      variant="contained" color={'secondary'} onClick={() => {
                        setOfficialCovidTemplate(false)
                        var temp = selectedOfficialCovidReport
                        localStorage.setItem('official_covid_report', JSON.stringify(temp))
                        setSelectedOfficialCovidReport({
                          reports: []
                        })
                        //console.log(temp)
                        setTimeout((temp) => {
                          console.log(temp)
                          setSelectedOfficialCovidReport(JSON.parse(localStorage.getItem('official_covid_report')))
                          setTimeout(() => {
                            setOfficialCovidTemplate(true)
                          }, 500);

                        }, 400);
                      }}>
                      Refresh Report
       <RefreshIcon /></Button>

                  </GridItem>

                  <GridItem md={10}>
                    <PDFViewer width="1000" height="1000" className="app" >
                      <OfficialCovidTemplate invoice={selectedOfficialCovidReport} />
                    </PDFViewer>
                  </GridItem>
                </GridContainer>

              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setOfficialCovidTemplate(false)
              setSelectedOfficialCovidReport({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
  {/* //for Edit Patient 11-jan-2023 */}

      

    </>
  );
}
