import React, { useEffect, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ReactLoading from 'react-loading';
import Slide from "@material-ui/core/Slide";
import SearchIcon from '@material-ui/icons/Search';
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaterialTable from "material-table";
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

//for dialogbox of invoice
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
//this screen created 05-may-2023 for Accessioning and phelobotomy

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 1000,
    },
});

export default function Lab_Tests() {
    const classes = useStyles();
    var date = new Date()
    var temp_role = localStorage.getItem('role')
    // date.setDate(date.getDate() - 2);
    date.setDate(date.getDate()-1);

    const [from, setFrom] = React.useState(date)
    const [to, setTo] = React.useState(new Date())
    const [patientId, setpatientId] = React.useState("");
  const [doctagtitle, setdoctagtitle] = React.useState("");
    const [width, height] = [window.innerWidth, window.outerWidth];
    const [loading, setLoading] = React.useState(true)
  const [selectedReport,setSelectedReport]=React.useState('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/286951')
// for pdf
var pdfurl="data:application/pdf;base64,"
  const [selectInvoice,setselectInvoice]=React.useState(false)
  const [rows, setRows] = React.useState([]);

  //for scanned image
  var tesTmg= "data:image/png;base64,";
  const [selectImage,setselectImage]=React.useState(false)
  const [rowsImg, setRowsImg] = React.useState([]);


    useEffect(() => {
        if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://invoicing.mpl-labs.pk/login"
        }
        if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://invoicing.mpl-labs.pk"
        }
        // fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
        //     setBranches(response)

        // })
        // var obj22={
        //     branch_id:localStorage.getItem("branch_id")
        //   }
        // fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetPanelsListBranch",
        // {
        //        method:"POST",
        // 'Content-Type':"application/json",
        // body: JSON.stringify(obj22)
        // }
        // ).then((res) => res.json()).then((response) => {
        //     console.log("GetPanelsListBranch"+JSON.stringify(response))
        //     // if(response.cred)
        //   response.push({
        //     Panel_Code: "Normal",
        //     Panel_Title: "Normal Patient"
        //   })
        //   setPanelList(response)
         
        // })
       
        setLoading(false)


    }, [])
    // Date.prototype.hhmm = function () {
    //     var mm = this.getMinutes();
    //     var hh = this.getHours();

    //     return [
    //         (hh > 9 ? '' : '0') + hh,
    //         (mm > 9 ? '' : '0') + mm
    //     ].join(':');
    // };

    // Date.prototype.ddmmyyy = function () {
    //     var mm = this.getMonth() + 1; // getMonth() is zero-based
    //     var dd = this.getDate();

    //     return [
    //         (dd > 9 ? '' : '0') + dd,
    //         (mm > 9 ? '' : '0') + mm,
    //         this.getFullYear()
    //     ].join('/');
    // };
//my work 28-jan-2023
const SearchHandler=()=>{
    // alert("Date From "+from.toISOString()+"Date To"+to.toISOString()+"Name"+name+"mobile"+mobile+"Reg No"+RegNo)
    setLoading(true)
    var obj22={
      doc_type:"PDF",
      doc_tag_or_title:doctagtitle,
      patient_id:patientId,
      date_start :from.toISOString(),
      date_end:to.toISOString()
          }
          console.log("obj22 is"+JSON.stringify(obj22))
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebDMS_ListsCallingUpFromServer",
        {
               method:"POST",
        'Content-Type':"application/json",
        body: JSON.stringify(obj22)
        }
        ).then((res) => res.json()).then((response) => {
            console.log("GetPanelsListBranch"+JSON.stringify(response)) 
        var the_rows = []

            // for pdf use this one 
            // window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' +response[3].dms_id)  
            response.map((data) => {
console.log("data is "+JSON.stringify(data))
var obj = {
  dms_id:data.dms_id,
  dms_tag:data.dms_tag,
  dms_title:data.dms_title,
  dms_date:(new Date(data.dms_date).getDate()) + "/" + (new Date(data.dms_date).getMonth() + 1) + "/" + (new Date(data.dms_date).getFullYear()),
  // dms_date:new Date(data.dms_date).toISOString().replace(/T.*/,'').split('-').reverse().join('-'),
  view: 
  <Button variant="contained" color="secondary"
  // style={{ backgroundColor: 'green', color: 'white' }} 
  //  key={data.invoice_no}
  disableElevation
   onClick={() => {       
       var obj22={
      dms_id:data.dms_id,
      doc_type:"PDF",
          }
          console.log("obj22 is"+JSON.stringify(obj22))
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebDMS_ImageOrPDF_BLOBCallingUp",
        {
               method:"POST",
        'Content-Type':"application/json",
        body: JSON.stringify(obj22)
        }
        ).then((res) => res.json()).then((response) => {
            console.log("WebDMS_ImageOrPDF_BLOBCallingUp"+JSON.stringify(response)) 
            // picture_image
            // setSelectedReport(`${tesTmg}`+response.picture_image)
            //for pdf
            setSelectedReport(`${pdfurl}`+response.text_base64)
            console.log("image base64"+response.picture_image)
            setTimeout(() => {
              setselectInvoice(true)
            }, 500);
        }) 
              }}>
                <  PictureAsPdfSharpIcon />
              </Button>
}
the_rows.push(obj)      
            })
        setRows(the_rows.reverse())
        setLoading(false)
        })
        //for Scanned Images
        var obj22={
          doc_type:"SCAN",
          doc_tag_or_title:doctagtitle,
          patient_id:patientId,
          date_start :from.toISOString(),
          date_end:to.toISOString()
              }
              console.log("obj22 is"+JSON.stringify(obj22))
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebDMS_ListsCallingUpFromServer",
            {
                   method:"POST",
            'Content-Type':"application/json",
            body: JSON.stringify(obj22)
            }
            ).then((res) => res.json()).then((response) => {
                console.log("GetPanelsListBranch"+JSON.stringify(response)) 
            var the_rows = []
    
                // for pdf use this one 
                // window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' +response[3].dms_id)  
                response.map((data) => {
    console.log("data is "+JSON.stringify(data))
    var obj = {
      dms_id:data.dms_id,
      dms_tag:data.dms_tag,
      dms_title:data.dms_title,
      dms_date:(new Date(data.dms_date).getDate()) + "/" + (new Date(data.dms_date).getMonth() + 1) + "/" + (new Date(data.dms_date).getFullYear()),
      // dms_date:new Date(data.dms_date).toISOString().replace(/T.*/,'').split('-').reverse().join('-'),
      view: 
      <Button variant="contained" 
      style={{ backgroundColor: 'green', color: 'white' }} 
      //  key={data.invoice_no}
      disableElevation
       onClick={() => {       
           var obj22={
          dms_id:data.dms_id,
          doc_type:"SCAN",
              }
              console.log("obj22 is"+JSON.stringify(obj22))
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebDMS_ImageOrPDF_BLOBCallingUp",
            {
                   method:"POST",
            'Content-Type':"application/json",
            body: JSON.stringify(obj22)
            }
            ).then((res) => res.json()).then((response) => {
                // console.log("WebDMS_ImageOrPDF_BLOBCallingUp"+JSON.stringify(response)) 
                // picture_image
                setSelectedReport(`${tesTmg}`+response.text_base64)
                //for pdf
                // setSelectedReport(`${pdfurl}`+response.text_base64)
                console.log("image base64"+`${tesTmg}`+response.text_base64)
                setTimeout(() => {
                  setselectImage(true)
                }, 500);
            }) 
                  }}>
                    < PhotoLibraryIcon />
                  </Button>
    }
    the_rows.push(obj)      
                })
            setRowsImg(the_rows.reverse())
            })
}

//for dialogBox of invoice pdf  
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
    return (
        <>
            <Paper className={classes.root}>
                <GridContainer style={{ padding: '2em'}}>
                    <GridItem xs={6} sm={6} md={6} lg={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="From"
                                format="dd/MM/yyyy"
                                value={from}
                maxDate={to}

                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setFrom(date)}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem>
                    {/* {
                        width < 600
                            ?
                            <GridItem xs={6} sm={6} md={0} lg={0}>

                            </GridItem> : ""
                    } */}

                    <GridItem xs={6} sm={6} md={6} lg={3}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="To"
                                format="dd/MM/yyyy"
                                value={to}
                               maxDate={new Date()}

                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setTo(date)}
                            />
                        </MuiPickersUtilsProvider>

                    </GridItem>
                    {/* {
                        width < 600
                            ?
                            <GridItem xs={6} sm={6} md={0} lg={0}>

                            </GridItem> : ""
                    } */}

                   
                            <GridItem xs={12} sm={6} md={6} lg={3}  >
         
         <TextField
         style={{width:"100%"}}
         
              id="outlined-required"
              label="Patient Id"
              value={patientId}
              onChange={(e) => {
                setpatientId(e.target.value);
              }}
              variant="outlined"
            />
                   
                            </GridItem>
                      
                    <GridItem xs={12} sm={6} md={6} lg={3}  >

                    <TextField
                    style={{width:"95%",marginLeft:"5%"}}
              id="outlined-required"
              label="Doc Tag or Title"
              value={doctagtitle}
              onChange={(e) => {
                setdoctagtitle(e.target.value);
              }}
              variant="outlined"
            />
              

                    </GridItem>
                </GridContainer>
                {/* <div style={{
        flexDirection: "row",
        flex: 1,
        textAlign: 'center',
        padding: "2em"
      }}> */}
        <GridContainer style={{ paddingBottom: '2em',justifyContent:"center",alignItem:"center",display:"flex"}}>
        
        {/* <TextField
          // required
          id="outlined-required"
          label="Reg #"
          variant="outlined"
          value={RegNo}
          onChange={(e) => {
            // setRows([])
            // setPrintiDicator(false)
            setRegNo(e.target.value)
          }}
        /> */}

<Button style={{marginLeft:15}} variant="contained" color="primary" disableElevation disabled={loading}  onClick={() => {
            
            SearchHandler()
           
          }}
  
                          >
                              Search<SearchIcon />
                          </Button>
      
      {/* </div> */}
      </GridContainer>


                {loading && localStorage.getItem('branch_id') != '0' ?
                    <center>
                        <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
                    </center>
                    :
                    <>
                        <GridContainer>
                     
                          <GridItem xs={12} sm={12} md={6} lg={6} >
                          <MaterialTable
                          style={{marginLeft:25}}
        title="PDF Files"
        data={rows}
        columns={[  
          { title: 'ID', field: 'dms_id' },
          { title: 'Tag ', field: 'dms_tag' },
          {title: 'Title', field:"dms_title"},
          { title: 'Date', field: 'dms_date' },
          { title: 'View', field: 'view' }

        ]}
        // maxBodyHeight={"50em"}
        options={{
          // selection: true,
          paging:true,
          minBodyHeight:40,
          // showSelectAllCheckbox:SelectionControl
        }}
      />
           </GridItem>
           <GridItem xs={12} sm={12} md={6} lg={6} >
                          <MaterialTable
                          style={{marginRight:25}}
        title="Image Scanned"
        data={rowsImg}
        columns={[  
          { title: 'ID', field: 'dms_id' },
          { title: 'Tag ', field: 'dms_tag' },
          {title: 'Title', field:"dms_title"},
          { title: 'Date', field: 'dms_date' },
          { title: 'View', field: 'view' }
        ]}
        // maxBodyHeight={"50em"}
        options={{
          // selection: true,
          paging:true,
          minBodyHeight:40,
          // showSelectAllCheckbox:SelectionControl
        }}
      />
           </GridItem>
                          
                        

                        </GridContainer>
                       
                    </>
                }


            </Paper>
   {/* ////////////////////// Pdf View Dialog box/////////////////////////// */}
 {selectInvoice && 
  <Dialog
  classes={{
    root: classes.center,
    paper: classes.modal
  }}

  maxWidth={'xl'}
  fullWidth={true}
  open={selectInvoice}
  TransitionComponent={Transition}
  keepMounted

  onClose={() => {
    setselectInvoice(false)
    // setSelectedReceipt({
    //   reports: []
    // })
  }}
  aria-labelledby="classic-modal-slide-title"
  aria-describedby="classic-modal-slide-description"
>
  <DialogTitle
    id="classic-modal-slide-title"
    disableTypography
    className={classes.modalHeader}
  >

    <h4 className={classes.modalTitle}><b>PDF View</b></h4>
  </DialogTitle>
  <DialogContent
    id="classic-modal-slide-description"
    className={classes.modalBody}
  >
     <center> <React.Fragment key={"sqq"} id>
          {/* <PDFViewer width="1000" height="1000" className="app" >
            <Invoice invoice={selectedInvoice} />
          </PDFViewer> */}
          {/* 09-oct-2023 height 400 ki jga 800 kr di */}
            {/* <iframe  src={selectedReport} width="100%" height={400}></iframe> */}
          
            <iframe  src={selectedReport} width="100%" height={800}></iframe>
            {/* <img  src={selectedReport} alt='test' ></img>      */}


        </React.Fragment>
        </center>

    
     
    {/* {
      selectedInvoice[0].sr != undefined && selectInvoice ?
        <center> <React.Fragment key={"sqq"} id>
          <PDFViewer width="1000" height="1000" className="app" >
            <Invoice invoice={selectedInvoice} />
          </PDFViewer>
        </React.Fragment>
        </center>
        : <></>
    } */}
  </DialogContent>
  <DialogActions className={classes.modalFooter}>

    <Button
      onClick={() => {
        setselectInvoice(false)
        // setSelectedReceipt({
        //   reports: []
        // })
      }}
      color="danger"
      simple
    >
      Ok
              </Button>
  </DialogActions>
</Dialog>

 }
   {/* ////////////////////// Scanned Image View Dialog box/////////////////////////// */}
   {selectImage && 
  <Dialog
  classes={{
    root: classes.center,
    paper: classes.modal
  }}

  maxWidth={'xl'}
  fullWidth={true}
  open={selectImage}
  TransitionComponent={Transition}
  keepMounted

  onClose={() => {
    setselectImage(false)
    // setSelectedReceipt({
    //   reports: []
    // })
  }}
  aria-labelledby="classic-modal-slide-title"
  aria-describedby="classic-modal-slide-description"
>
  <DialogTitle
    id="classic-modal-slide-title"
    disableTypography
    className={classes.modalHeader}
  >

    <h4 className={classes.modalTitle}><b>Image View</b></h4>
  </DialogTitle>
  <DialogContent
    id="classic-modal-slide-description"
    className={classes.modalBody}
  >
     <center> <React.Fragment key={"sqq"} id>
          {/* <PDFViewer width="1000" height="1000" className="app" >
            <Invoice invoice={selectedInvoice} />
          </PDFViewer> */}
            {/* <iframe  src={selectedReport} width="100%" height={400}></iframe> */}
            <img  src={selectedReport} alt='test' ></img>     


        </React.Fragment>
        </center>

    
     
    {/* {
      selectedInvoice[0].sr != undefined && selectInvoice ?
        <center> <React.Fragment key={"sqq"} id>
          <PDFViewer width="1000" height="1000" className="app" >
            <Invoice invoice={selectedInvoice} />
          </PDFViewer>
        </React.Fragment>
        </center>
        : <></>
    } */}
  </DialogContent>
  <DialogActions className={classes.modalFooter}>

    <Button
      onClick={() => {
        setselectImage(false)
        // setSelectedReceipt({
        //   reports: []
        // })
      }}
      color="danger"
      simple
    >
      Ok
              </Button>
  </DialogActions>
</Dialog>

 }
  
        </>
    );
}

